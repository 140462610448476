<template>
  <v-container>
    <v-row>
      <v-col>
        <h3>ຂໍ້ມູນປະຫວັດການໂອນພ້ອຍ</h3>
      </v-col>
      <v-col>
        <v-select
            v-model="selectedType"
            :items="transferTypes"
            :item-text="showText"
            item-value="id"
            label="ປະເພດ"
            dense
            outlined
            clearable
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @keyup.enter="Search()"
            dense
            outlined
            clearable
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <v-card class="pa-2">
          <v-data-table
              :headers="headers"
              :items="data"
              :search="search"
              :disable-pagination="true"
              hide-default-footer
          >
            <template v-slot:[`item.created_at`]="{ item }">
              {{ moment(item.created_at).format("hh:mm DD-MM-YYYY") }}
            </template>
            <template v-slot:[`item.point`]="{ item }">
             <v-chip color="success" label>
               {{ Intl.NumberFormat().format(item.point) }}
             </v-chip>
            </template>
            <template v-slot:[`item.icon`]>
              <v-icon small class="primary-color">mdi-arrow-right</v-icon>
            </template>

          </v-data-table>
          <br/>
          <template>
            <Pagination
                v-if="pagination.total_pages > 1"
                :pagination="pagination"
                :offset="offset"
                @paginate="fetchData()"
            ></Pagination>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "WalletView",
  title() {
    return `Wallet Detail|${this.title}`;
  },
  data() {
    return {
      title: "Hal Point",
      headers: [
        {text: "ຈາກກະເປົາ", value: "from_wallet.name",width: "220px",sortable: false},
        {text: "", value: "icon",width: "40px",sortable: false},
        {text: "ຫາກະເປົາ", value: "to_wallet.name",width: "220px",sortable: false},
        {text: "ຈຳນວນ", value: "point",width: "130px"},
        {text: "ລາຍລະອຽດ", value: "description",width: "130px",sortable: false},
        {text: "ປະເພດ", value: "transfer_type.type",width: "130px"},
        {text: "ວັນທີໂອນ", value: "created_at", width: "180px", sortable: false},
      ],
      loading: false,
      data: [],
      //Pagination
      offset: 12,
      pagination: {},
      per_page: 15,
      search: "",
      oldVal: "",
      departments: [],
      selectedType: "",
      transferTypes: [
      ],
    }
  },
  methods: {
    async fetchDepartment() {
      this.$store.commit("Loading_State", true);
      await this.$axios
          .get("admin/list-wallet-transaction-types")
          .then((res) => {
            if (res.data.code == 200) {
              this.loading = false;
              this.transferTypes = res.data.data;
              this.selectedType = res.data.data[0].id;
            }
          })
          .catch((error) => {
            this.$store.commit("Loading_State", false);
            if (error.response.status == 422) {
              let obj = error.response.data.errors;
              for (let [key, message] of Object.entries(obj)) {
                this.server_errors[key] = message[0];
              }
            }
          });
    },

  async  fetchData() {
    this.$store.commit("Loading_State", true);
    // for (let [key, message] of Object.entries(this.transferTypes)){
    //   this.selectedType = this.transferTypes[0].id;
    // }
   await this.$axios
          .post("admin/report-wallet-transaction-points",  {
            wallet_id: this.$route.params.id,
            transaction_type_id: this.selectedType,
          })
          .then((res) => {
            if (res.data.code == 200) {
              this.loading = false;
              this.$store.commit("Loading_State", false);
              this.data = res.data.data;
              // this.pagination = res.data.data.pagination;
            }
          })
          .catch((error) => {
            this.$store.commit("Loading_State", false);
            if (error.response.status == 422) {
              let obj = error.response.data.errors;
              for (let [key, message] of Object.entries(obj)) {
                this.server_errors[key] = message[0];
              }
            }
          });
    },
    showText(txt) {
      if(txt.name == "earn") {
        return 'ຍອດຮັບ'
      } else if (txt.name == "spend") return 'ຍອດໂອນ'
    }
  },
  watch:{
    "selectedType":function (value){
       if(value){
         this.fetchData();
       }
    }
  },

  created() {
    this.fetchDepartment();
    if(this.selectedType){
      this.fetchData();
    }
  },
};
</script>

<style lang="scss">
@import "../../../public/scss/main.scss";
</style>
