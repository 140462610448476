import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import mixin from '@/Helpers/Mixin'
import can from '@/Helpers/Can'
// import store from '@/store'
import vuetify from './plugins/vuetify'
import '@/store/apis';
import moment from 'moment';
import excel from 'vue-excel-export';
import firebase from 'firebase/app'


import ModalAdd from './components/modals/AddView';
import ModalEdit from './components/modals/EditView';
import ModalView from './components/modals/ModalView';
import ModalDelete from './components/modals/DeleteView';
import PaginationView from "@/plugins/pagination/paginationView";

Vue.component('ModalAdd', ModalAdd);
Vue.component('ModalEdit', ModalEdit);
Vue.component('ModalView', ModalView);
Vue.component('ModalDelete', ModalDelete);
Vue.component('Pagination', PaginationView);
Vue.use(excel);
Vue.prototype.moment = moment;
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCXlPQj80Y96sP6yiRIarXEXmcdhMedZuM",
  authDomain: "hal-logistics-admin.firebaseapp.com",
  projectId: "hal-logistics-admin",
  storageBucket: "hal-logistics-admin.appspot.com",
  messagingSenderId: "798055465936",
  appId: "1:798055465936:web:077ddb170483d7c8506fd5"
};


firebase.initializeApp(firebaseConfig);
Vue.mixin(mixin);
Vue.config.productionTip = false
Vue.prototype.$can = can;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
