<template>
  <v-container>
    <v-row justify="center">
      <v-col>
        <v-card class="pa-2">
          <v-card-title>
            ຂໍ້ມູນລາຍລະອຽດ ການໂອນພ້ອຍ
            <v-divider class="mx-4" vertical></v-divider>
            <v-spacer></v-spacer>
<!--            <v-btn @click="exportTransaction()" class="btn-success elevation-0 mr-4"-->
<!--            >-->
<!--              <v-icon>mdi-table</v-icon>-->
<!--              Export Excel-->
<!--            </v-btn>-->
<!--            <v-btn @click="exportTransaction()" class="btn-info elevation-0"-->
<!--            >-->
<!--              <v-icon>mdi-file</v-icon>-->
<!--              Export Pdf-->
<!--            </v-btn>-->
            <!--
            <v-select
              outlined
              dense
              :items="statuses"
              v-model="selectedStatus"
              item-text="name"
              item-value="name"
              label="ສະຖານະ"
            ></v-select>
            <v-spacer></v-spacer>
                        -->
<!--            <v-text-field-->
<!--                v-model="search"-->
<!--                clearable-->
<!--                prepend-inner-icon="mdi-magnify"-->
<!--                label="Search"-->
<!--                single-line-->
<!--                hide-details-->
<!--                @keyup.enter="Search()"-->
<!--            ></v-text-field>-->
          </v-card-title>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    ຈາກກະເປົາ
                  </th>
                  <th class="text-left">
                    ຫາກະເປົາ
                  </th>
                  <th class="text-left">
                    ຈຳນວນ
                  </th>
                  <th class="text-left">
                    ລາຍລະອຽດ
                  </th>
                  <th class="text-left">
                    ປະເພດ
                  </th><th class="text-left">
                    ວັນທີ
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="item in transactions"
                    :key="item.id"
                >
                  <td>

                    <div v-if="item.transfer_type === 'department'">
                      {{ item.from_wallet.account_name }}
                    </div>
                    <div v-else>
                      {{ item.from_wallet.name }}
                    </div>

                  </td>
                  <td>
                    <div v-if="item.transfer_type === 'department'">
                      {{ item.to_wallet.account_name }}
                    </div>
                    <div v-else>
                      {{ item.from_wallet.name }}
                    </div>
                  <td >{{ Intl.NumberFormat().format(item.point) }}</td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.transfer_type.type }}</td>
                  <td>{{ moment(item.created_at).format("hh:mm DD-MM-YYYY") }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

<!--          <v-data-table-->
<!--              :headers="headers"-->
<!--              :items="transactions"-->
<!--              :search="search"-->
<!--              :disable-pagination="true"-->
<!--              hide-default-footer-->
<!--          >-->
<!--            <template v-slot:[`item.created_at`]="{ item }">-->
<!--              {{ moment(item.created_at).format("hh:mm DD-MM-YYYY") }}-->
<!--            </template>-->
<!--            <template v-slot:[`item.from_wallets`]>-->
<!--              <div v-if="item.transfer_type === 'department'">-->
<!--                <div>{{item.from_wallets.account_name}}</div>-->
<!--              </div>-->
<!--              <div v-else>-->
<!--                <div>{{item.from_wallets.name}}</div>-->
<!--              </div>-->
<!--            </template>-->
<!--            <template v-slot:[`item.to_wallet`]>-->
<!--              <div v-if="item.transfer_type == 'department'">-->
<!--                <div>{{item.to_wallet.account_name}}</div>-->
<!--              </div>-->
<!--              <div v-else>-->
<!--                <div>{{item.to_wallet.name}}</div>-->
<!--              </div>-->
<!--            </template>-->

<!--            <template v-slot:[`item.icon`]>-->
<!--              <v-icon small class="primary-color">mdi-arrow-right</v-icon>-->
<!--            </template>-->
<!--            <template v-slot:[`item.spend`]="{ item }">-->
<!--              <v-chip color="error" label>-->
<!--                {{ Intl.NumberFormat().format(item.point.spend) }}-->
<!--              </v-chip>-->
<!--            </template>-->
<!--            <template v-slot:[`item.point`]="{ item }">-->
<!--              <v-chip color="info" label>-->
<!--                {{ Intl.NumberFormat().format(item.point) }}-->
<!--              </v-chip>-->
<!--            </template>-->
<!--          </v-data-table>-->
          <br/>
<!--          <template>-->
<!--            <Pagination-->
<!--                v-if="pagination.total_pages > 1"-->
<!--                :pagination="pagination"-->
<!--                :offset="offset"-->
<!--                @paginate="fetchData()"-->
<!--            ></Pagination>-->
<!--          </template>-->
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import {GetOldValueOnInput} from "@/Helpers/GetValue";
// import queryOption from "@/Helpers/queryOption";

export default {
  name: "DepartmentView",
  title() {
    return `Transaction Point Details|${this.title}`;
  },
  data() {
    return {
      title: "Transaction Point",
      headers: [
        {text: "ຈາກກະເປົາ", value: "from_wallet.name",width: "120px",sortable: false},
        {text: "", value: "icon",width: "40px",sortable: false},
        {text: "ຫາກະເປົາ", value: "to_wallet",width: "270px",sortable: false},
        {text: "ຈຳນວນ", value: "point",width: "130px"},
        {text: "ລາຍລະອຽດ", value: "description",width: "130px",sortable: false},
        {text: "ປະເພດ", value: "transfer_type",width: "130px"},
        {text: "ວັນທີ", value: "created_at",width: "180px",sortable: false},
        // {text: "ຈັດການຂໍ້ມູນ", value: "actions", sortable: false,width: "130px",align: "center"},
      ],
      loading: false,
      transactions: [],

      //Pagination
      offset: 12,
      pagination: {},
      per_page: 15,
      search: "",
      oldVal: "",
      selectedType: "department",

      start_date: "",
      end_date: "",
      start_menu: false,
      end_menu: false,

      transferTypes: [
        {
          value: 'department',
          name: "ພະແນກ",
        },
        {
          value: 'customer',
          name: "ລູກຄ້າ",
        },{
          value: 'employee',
          name: "ພະນັກງານ",
        },
      ],
    };
  },
  methods: {

    fetchData() {
      this.$store.commit("Loading_State", true);
      const data = {
        from_wallet_id: 'all',
        to_wallet_id: 'all',
        transaction_status_id:'all',
        transfer_type: this.selectedType,
        start_date: this.start_date,
        end_date: this.end_date,
      }
      this.$axios
          .get(`admin/list-wallet-transaction-details/${this.$route.params.id}`,
          //     {
          //   params: queryOption([
          //     {page: this.pagination.current_page},
          //     {
          //       per_page: this.per_page
          //     },
          //   ]),
          // }
              data
          )
          .then((res) => {
            if (res.data.code == 200) {
              this.loading = false;
              this.$store.commit("Loading_State", false);
              this.transactions = res.data.data;
              console.log(this.transactions)
              // this.pagination = res.data.data.pagination;
            }
          })
          .catch((error) => {
            this.$store.commit("Loading_State", false);
            if (error.response.status == 422) {
              let obj = error.response.data.errors;
              for (let [key, message] of Object.entries(obj)) {
                this.server_errors[key] = message[0];
              }
            }
          });
    },
    reset() {
      this.$refs.form.reset();
    },
    AddPoint() {
      this.$router.push({name: 'transaction-points-add'});
    },
    Search() {
      GetOldValueOnInput(this);
    },
  },
  watch: {

    "selectedType":function (){
      this.fetchData();
    },
    "start_date":function (){
      this.fetchData();
    },
    "end_date":function (){
      this.fetchData();
    },
    search: function (value) {
      if (value == "") {
        this.fetchData();
      }
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style lang="scss">
@import "../../../public/scss/main.scss";
</style>
