<template>
  <v-container>
    <v-card class="elevation-0">
      <v-card-title>
        <h4>ເຕີມພ້ອຍ</h4>
      </v-card-title>
      <v-card-text>
<!--        <v-stepper alt-labels>-->
<!--          <v-stepper-header>-->
<!--            <v-stepper-step step="1">-->
<!--              Step 1-->
<!--            </v-stepper-step>-->

<!--            <v-divider></v-divider>-->

<!--            <v-stepper-step step="2">-->
<!--              Step 2-->
<!--            </v-stepper-step>-->

<!--            <v-divider></v-divider>-->

<!--            <v-stepper-step step="3">-->
<!--              Step 3-->
<!--            </v-stepper-step>-->
<!--          </v-stepper-header>-->
<!--&lt;!&ndash;          Step Content&ndash;&gt;-->
<!--          <v-stepper-items>-->
<!--            <v-stepper-content step="1">-->
<!--              <v-card-->
<!--                  class="mb-12"-->
<!--                  height="200px"-->
<!--              ></v-card>-->

<!--              <v-btn-->
<!--                  color="primary"-->
<!--                  @click="e1 = 2"-->
<!--              >-->
<!--                Continue-->
<!--              </v-btn>-->

<!--              <v-btn text>-->
<!--                Cancel-->
<!--              </v-btn>-->
<!--            </v-stepper-content>-->

<!--            <v-stepper-content step="2">-->
<!--              <v-card-->
<!--                  class="mb-12"-->
<!--                  color="grey lighten-1"-->
<!--                  height="200px"-->
<!--              ></v-card>-->

<!--              <v-btn-->
<!--                  color="primary"-->
<!--                  @click="e1 = 3"-->
<!--              >-->
<!--                Continue-->
<!--              </v-btn>-->

<!--              <v-btn text>-->
<!--                Cancel-->
<!--              </v-btn>-->
<!--            </v-stepper-content>-->

<!--            <v-stepper-content step="3">-->
<!--              <v-card-->
<!--                  class="mb-12"-->
<!--                  color="grey lighten-1"-->
<!--                  height="200px"-->
<!--              ></v-card>-->

<!--              <v-btn-->
<!--                  color="primary"-->
<!--                  @click="e1 = 1"-->
<!--              >-->
<!--                Continue-->
<!--              </v-btn>-->

<!--              <v-btn text>-->
<!--                Cancel-->
<!--              </v-btn>-->
<!--            </v-stepper-content>-->
<!--          </v-stepper-items>-->

<!--        </v-stepper>-->

       <v-container>
         <v-form ref="form" lazy-validation>
           <v-row>
             <v-col cols="12">
               <v-autocomplete
                   v-model="selectedWallet"
                   :items="wallets"
                   item-text="account_name"
                   item-value="id"
                   label="ເລືອກ Wallet"
                   dense
                   :rules="walletRule"
                   outlined
               ></v-autocomplete>
               <p class="errors" >
                 {{ server_errors.wallet_id }}
               </p>
             </v-col>
           </v-row>
           <v-row>
             <v-col cols="12">
               <v-autocomplete
                   v-model="selectedTransferType"
                   :items="transferTypes"
                   :item-text="showText"
                   item-value="id"
                   label="ປະເພດການໂອນ"
                   dense
                   :rules="transferRule"
                   outlined
               ></v-autocomplete>
               <p class="errors" >
                 {{ server_errors.transfer_type_id }}
               </p>
             </v-col>
           </v-row>
           <v-row>
             <v-col cols="12">
               <v-text-field
                   label="Point"
                   class="input-number"
                   type="number"
                   required
                   v-model="formState.point"
                   :rules="pointRule"
                   dense
                   outlined
               ></v-text-field>
               <p v-if="formState.point">{{Intl.NumberFormat().format(formState.point)}}</p>
               <p class="errors">
                 {{ server_errors.point }}
               </p>
             </v-col>
           </v-row>
           <v-row>
             <v-col cols="12">
               <v-text-field
                   label="ຄຳອະທິບາຍ"
                   required
                   v-model="formState.description"
                   :rules="descriptionRule"
                   dense
                   outlined
               ></v-text-field>
               <p class="errors">
                 {{ server_errors.description }}
               </p>
             </v-col>
           </v-row>
         </v-form>
         <v-card-actions>
           <v-spacer></v-spacer>
           <v-btn class="btn-info mr-4 elevation-0" @click="closeAddPoint()">
             ຍ້ອນກັບ
           </v-btn>
           <v-btn
               class="btn-primary elevation-0"
               text
               :loading="loading"
               :disabled="loading"
               @click="validateAddPoint()"
           >
             ບັນທຶກ
           </v-btn>
         </v-card-actions>
       </v-container>
      </v-card-text>
    </v-card>

    <ModalAdd>
      <template @close="close">
        <v-card>
          <v-btn
              icon
              dark
              @click="closeAddModal"
              class="pa-4"
          >
            <v-icon class="primary-color">mdi-close</v-icon>
          </v-btn>

          <v-card-text>
            <v-container>
              <h2 class="text-center display-5 black--text mt-4 mb-2">
                ຢືນຢັນການເຕີມພ້ອຍ
              </h2>
              <p class="errors text-center">{{ moment(new Date()).format('DD-MM-YYYY hh:mm:ss') }}</p>
              <v-row class="text-center">
                <v-col cols="12">
                  <v-card class="elevation-0 light-blue">
                    <v-card-text>
                      <h3>{{transferType.name}} - {{transferType.short_name}}</h3>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <br>
              <v-simple-table
                  class="elevation-1"
              >
                <template v-slot:default>
                  <tbody>
                  <tr
                  >
                    <td style="width: 160px"> <span class="info--text">ເລກບັນຊີປາຍທາງ:</span></td>
                    <td><span>{{fromWalletAccount.account_number}} - {{fromWalletAccount.account_name}}</span> </td>
                  </tr>
                  <tr
                  >
                    <td style="width: 160px"><span class="info--text">ປະເພດທຸລະກຳ:</span>
                    </td> <td><span>{{transferType.name}} - {{transferType.short_name}}</span> </td>
                  </tr>
                  <tr
                  >
                  <tr
                  >
                    <td style="width: 160px"><span class="info--text">ລາຍລະອຽດ:</span>
                    </td> <td><span>{{formState.description}}</span> </td>
                  </tr>
                  <tr
                  >
                    <td style="width: 160px"><span class="info--text">ຍອດລວມທັງໝົດ:</span></td>
                    <td><span>{{Intl.NumberFormat().format(formState.point)}} Point</span></td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <br />
              <p class="text-center display-5 black--text mb-8 mt-0">Input Code from SMS {{fromWalletAccount.phone}}</p>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="xl-6 lg-6 md-12 sm-12 xm-12">
                    <v-otp-input
                        ref="otpInput"
                        input-classes="otp-input"
                        separator=""
                        :num-inputs="6"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        class="otp"
                        v-model="otp"
                    />
                    <!--                    <v-btn text @click="handleClearInput()" small style="margin:auto;">Clear</v-btn>-->
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p class="errors">
                      {{server_errors.otp}}
                    </p>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
<!--              <v-btn-->
<!--                  color="darken-0"-->
<!--                  class="btn-info mr-4"-->
<!--                  :loading="requestLoading"-->
<!--                  :disabled="requestLoading"-->
<!--                  @click="requestOtp"-->
<!--              >-->
<!--                ສົ່ງຄຳຂໍໃໝ່-->
<!--              </v-btn>-->
              <v-btn
                  class="btn-primary elevation-0"
                  :loading="btnVerify"
                  :disabled="btnVerify"
                  @click="verifyOtp"
              >
                ຢືນຢັນ OTP
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalAdd>

    <!--    ViewModel-->
    <ModalView>
      <template @close="close">
        <v-card>
          <!--          <v-btn-->
          <!--              icon-->
          <!--              dark-->
          <!--              @click="closeViewModal"-->
          <!--              class="pa-4"-->
          <!--          >-->
          <!--            <v-icon class="primary-color">mdi-close</v-icon>-->
          <!--          </v-btn>-->

          <v-card-text>
            <v-container>
              <div class="text-center">
                <v-icon class="success--text" style="font-size: 54px;">mdi-checkbox-marked-circle</v-icon>
              </div>
              <h2 class="text-center display-5 black--text mt-4 mb-2">
                ເຕີມພ້ອຍສຳເລັດແລ້ວ
              </h2>
              <p class="errors text-center">{{ moment(successData.created_at).format('DD-MM-YYYY hh:mm:ss') }}</p>
              <v-row class="text-center">
                <v-col cols="12">
                  <v-card class="elevation-0 light-blue">
                    <v-card-text>
                      <h3>{{successData.refer_number}}</h3>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <br>
              <v-simple-table
                  class="elevation-0"
              >
                <template v-slot:default>
                  <tbody>
                  <tr
                  >
                    <td style="width: 160px"> <span class="info--text">ເລກບັນຕົ້ນທາງ:</span></td>
                    <td v-if="successData.from_wallet"><span>{{successData.from_wallet.account_number}} - {{successData.from_wallet.account_name}}</span> </td>
                  </tr>
                  <tr
                  >
                    <td style="width: 160px"><span class="info--text">ປະເພດທຸລະກຳ:</span>
                    </td> <td v-if="successData.transfer_type"><span>{{successData.transfer_type.name}} - {{successData.transfer_type.short_name}}</span> </td>
                  </tr>

                  <tr
                  >
                    <td style="width: 160px"><span class="info--text">ຍອດລວມທັງໝົດ:</span></td>
                    <td><span>{{Intl.NumberFormat().format(successData.totalPoint)}} Point</span></td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  class="btn-success elevation-0"
                  @click="closeViewModal"
              >
                ສຳເລັດ
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalView>
    <div id="recaptcha-container"></div>
  </v-container>
</template>
<script>
import firebase from "firebase";
export default {
  name: "DepartmentView",
  title() {
    return `Add Point|${this.title}`;
  },
  data() {
    return {
      title: "Hal Point",
      loading: false,
      status: false,
      btnVerify: false,
      requestLoading:false,
      otp:"",
      phone:"",
      wallets: [],
      selectedWallet:"",
      formState: {},
      fromWalletAccount:{},
      dataId: "",
      server_errors: {
        name: "",
        type: ""
      },
      transferTypes: [],
      transferType:{},
      selectedTransferType:"",
      successData:{},
      //Validation
      nameRule: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length >= 2) || "Name must be less than 2 characters",
      ],
      descriptionRule: [
        (v) => !!v || "Description is required",
      ],
      typeRules: [(v) => !!v || "Type is required"],
      transferRule: [(v) => !!v || "Transfer Type is required"],
      walletRule: [(v) => !!v || "Wallet is required"],
      pointRule: [(v) => !!v || "Point is required"],
    };
  },
  methods: {
    openAddModal() {
      this.$store.commit("modalAdd_State", true);
    },
    closeAddPoint(){
      this.$router.push({name: "wallet"});
      this.formState = {};
      this.reset();
    },
    closeAddModal() {
      this.formState = {};
      this.$store.commit("modalAdd_State", false);
    },

    fetchTransferType() {
      this.$store.commit("Loading_State", true);
      this.$axios
          .get("admin/list-wallet-transfer-types")
          .then((res) => {
            if (res.data.code == 200) {
              this.loading = false;
              this.$store.commit("Loading_State", false);
              this.transferTypes = res.data.data.filter(x => x.type == "department");
            }
          })
          .catch((error) => {
            this.$store.commit("Loading_State", false);
            if (error.response.status == 422) {
              let obj = error.response.data.errors;
              for (let [key, message] of Object.entries(obj)) {
                this.server_errors[key] = message[0];
              }
            }
          });
    },
    fetchWallet() {
      this.$axios
          .get("admin/list-wallet-manager")
          .then((res) => {
            if (res.data.code == 200) {
              this.loading = false;
              this.$store.commit("Loading_State", false);
              this.wallets = res.data.data;
            }
          })
          .catch((error) => {
            this.$store.commit("Loading_State", false);
            if (error.response.status == 422) {
              let obj = error.response.data.errors;
              for (let [key, message] of Object.entries(obj)) {
                this.server_errors[key] = message[0];
              }
            }
          });
    },

    reset() {
      this.$refs.form.reset();
    },

    async  validateAddPoint() {
      if (this.$refs.form.validate() == true) {
        try {
          this.loading = true;
          await this.$axios
              .post("admin/validate-add-point-department/" + this.selectedWallet, {
                point: this.formState.point,
                transfer_type_id:this.selectedTransferType,
                description:this.formState.description
              }).then((res) => {
                if (res.status == 200) {
                  this.requestOtp();
                }
              }).catch((error) => {
                this.loading = false;
                this.$store.commit("Toast_State", {
                  value: true,
                  color: "error",
                  msg: error.response.data.message,
                });
                if (error.response.status == 422) {
                  let obj = error.response.data.errors;
                  for (let [key, customer] of Object.entries(obj)) {
                    this.server_errors[key] = customer[0];
                  }
                }
              });
        } catch (error) {
          this.$store.commit("Toast_State", {
            value: true,
            color: "error",
            msg: 'ເກີດຂໍ້ຜິດພາດບາງຢ່າງ',
          });
        } finally {
          this.loading = false;
        }
      }
    },
    async AddMorePoint(id_token) {
      if (this.$refs.form.validate() == true) {
        try {
          this.loading = true;
          const data = {
            point: this.formState.point,
            transfer_type_id:this.selectedTransferType,
            description:this.formState.description,
          }
          await this.$axios
              .post("admin/add-point-department/" + this.selectedWallet,data,{headers: {idToken: id_token}}
              ).then((res) => {
                if (res.data.code == 200) {
                  this.successData = res.data.data;
                  this.$store.commit("modalAdd_State", false);
                }
              }).catch((error) => {
                this.loading = false;
                this.$store.commit("Toast_State", {
                  value: true,
                  color: "error",
                  msg: error.response.data.message,
                });
                if (error.response.status == 422) {
                  let obj = error.response.data.errors;
                  for (let [key, customer] of Object.entries(obj)) {
                    this.server_errors[key] = customer[0];
                  }
                }
              });
        } catch (error) {
          this.$store.commit("Toast_State", {
            value: true,
            color: "error",
            msg: 'ເກີດຂໍ້ຜິດພາດບາງຢ່າງ',
          });
        } finally {
          this.loading = false;
          this.$store.commit("modalView_State", true);
        }
      }
    },

    requestOtp() {
      this.initReCaptcha();
      this.otp = '';
      this.requestLoading = true;
      let countryCode = "+85620"; //laos
      let phoneNumber = countryCode + this.fromWalletAccount.phone;
      const appVerifier = this.appVerifier;
      setTimeout(() => {
        firebase.auth().languageCode = "en";
        firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
            .then(confirmationResult => {
              window.confirmationResult = confirmationResult;
              this.requestLoading = false;
              // this.$store.commit("modalAdd_State", true);
              this.openAddModal();
            })
            .catch((error) => {
              this.error = error;
              if (error == 400) {
                this.store.commit("Toast_State", {
                  value: true,
                  color: "error",
                  msg: error.errors.message,
                });
              }
              this.requestLoading = false;
            }, 15000);
      })
    },
    verifyOtp() {
      this.initReCaptcha();
      this.btnVerify = true;
      let code = this.otp;
      window.confirmationResult.confirm(code).then((res) => {
        if (res) {
          this.btnVerify = false;
          const token = (res.user._lat);
          this.AddMorePoint(token);
        }
      }).catch((error) => {
        this.btnVerify = false;
        this.server_errors.otp = error.message;
        this.store.commit("Toast_State", {
          value: true,
          color: "error",
          msg: error.message,
        });
      });
    },
    closeViewModal() {
      this.$store.commit("modalView_State", false);
      this.$router.push({name: "wallet"})
    },
    initReCaptcha() {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            "expired-callback": function () {
            },
          }
      );
      //
      this.appVerifier = window.recaptchaVerifier;
    },
    showText(txt) {
      if (txt) {
        return txt.name + `(${txt.short_name})`;
      }
      else return txt.name + `(${txt.short_name})`;
    },
  },
  watch: {
    "formState.point": function () {
      this.server_errors.point = "";
    },
    selectedTransferType: function (value) {
      this.server_errors.transfer_type_id = "";
      this.transferType =  this.transferTypes.find(x => x.id === value)
    },
    selectedWallet:function (value) {
      this.fromWalletAccount =  this.wallets.find(x => x.id === value);
    },
    "formState.description": function () {
      this.server_errors.description = "";
    },

  },
  created() {
    this.fetchWallet();
    this.fetchTransferType();
  },
};
</script>

<style lang="scss">
@import "../../../public/scss/main.scss";
</style>