<template>
  <v-container>
    <v-row class="mb-n6">
      <v-col>
        <v-btn @click="openAddModal()" class="btn-primary elevation-0"
        >
          <v-icon>mdi-plus</v-icon>  ເພີມປະເພດການໂອນ
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <v-card class="pa-2">
          <v-card-title>
            ຂໍ້ມູນ ປະເພດການໂອນ ({{ pagination.total }})
            <v-divider class="mx-4" vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                clearable
                prepend-inner-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                @keyup.enter="Search()"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="data"
              :search="search"
              :disable-pagination="true"
              hide-default-footer
          >
            <template v-slot:[`item.created_at`]="{ item }">
              {{ moment(item.created_at).format("hh:mm DD-MM-YYYY") }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-4" @click="OpenModalEdit(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item.id)"> mdi-delete</v-icon>
            </template>
          </v-data-table>
          <br/>
          <template>
            <Pagination
                v-if="pagination.total_pages > 1"
                :pagination="pagination"
                :offset="offset"
                @paginate="fetchData()"
            ></Pagination>
          </template>
        </v-card>
      </v-col>
    </v-row>

    <!-- Modal Add-->
    <ModalAdd>
      <template @close="close">
        <v-card>
          <v-card-title>
            <p>ເພີ່ມ Transfer Type</p>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="ຊື່ພະແນກ"
                        required
                        v-model="formState.name"
                        :rules="nameRule"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.name }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="ຊື່ອັກສອນຫຍໍ້"
                        required
                        v-model="formState.short_name"
                        :rules="short_nameRule"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.short_name }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                        v-model="selectedType"
                        :items="departmentTypes"
                        item-text="name"
                        item-value="value"
                        label="ປະເພດ"
                        dense
                        :rules="typeRules"
                    ></v-autocomplete>
                    <p class="errors">
                      {{ server_errors.type }}
                    </p>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeAddModal()">
                ຍົກເລີກ
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="AddItem()"
              >
                ບັນທຶກ
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalAdd>

    <!--    Edit Modal-->
    <ModalEdit>
      <template @close="close">
        <v-card>
          <v-card-title>
            <p>ແກ້ໄຂ Transaction Type</p>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="ຊື່ພະແນກ"
                        required
                        v-model="edit_data.name"
                        :rules="nameRule"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.name }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="ຊື່ອັກສອນຫຍໍ້"
                        required
                        v-model="edit_data.short_name"
                        :rules="short_nameRule"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.short_name }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                        v-model="edit_data.type"
                        :items="departmentTypes"
                        item-text="name"
                        item-value="value"
                        label="ປະເພດ"
                        dense
                        :rules="typeRules"
                    ></v-autocomplete>
                    <p class="errors">
                      {{ server_errors.type }}
                    </p>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeUpdateModal()">
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="updateItem()"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalEdit>

    <!--    Delete Modal-->
    <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="deleteItemConfirm"
          >OK
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete>
  </v-container>
</template>

<script>
import {GetOldValueOnInput} from "@/Helpers/GetValue";
import queryOption from "@/Helpers/queryOption";

export default {
  name: "TransferTypeView",
  title() {
    return `Transfer Type|${this.title}`;
  },
  data() {
    return {
      title: "Hal Point",
      headers: [
        {text: "ຊື່ອັກສອນຫຍໍ້", value: "short_name"},
        {text: "ຊື່", value: "name"},
        {text: "Type", value: "type"},
        {text: "Created", value: "created_at"},
        {text: "ຈັດການຂໍ້ມູນ", value: "actions", sortable: false},
      ],
      loading: false,
      status: false,
      data: [],
      formState: {},
      edit_data: {},
      dataId: "",
      server_errors: {
        name: "",
        short_name: "",
        type: ""
      },
      //Pagination
      offset: 12,
      pagination: {},
      per_page: 15,
      search: "",
      oldVal: "",
      selectedType: "department",
      departmentTypes: [
        {
          value: 'department',
          name: "ພະແນກ",
        },
        {
          value: 'customer',
          name: "Customer",
        },{
          value: 'employee',
          name: "ພະນັກງານ",
        },
      ],
      //Validation
      nameRule: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length >= 2) || "Name must be less than 2 characters",
      ],
      short_nameRule: [
        (v) => !!v || "Short name is required",
      ],
      typeRules: [(v) => !!v || "Type is required"],
    };
  },
  methods: {
    openAddModal() {
      this.$store.commit("modalAdd_State", true);
    },
    async AddItem() {
      if (this.$refs.form.validate() == true) {
        try {
          this.loading = true;
          await this.$axios
              .post("admin/add-wallet-transfer-type", {
                name: this.formState.name,
                short_name: this.formState.short_name,
                type: this.selectedType
              }).then((res) => {
                if (res.data.code == 200) {
                  this.closeAddModal();
                  this.formState = {};
                  this.fetchData();
                  this.reset();
                  this.$store.commit("Toast_State", {
                    value: true,
                    color: "success",
                    msg: res.data.message,
                  });
                }
              }).catch((error) => {
                this.loading = false;
                this.$store.commit("Toast_State", {
                  value: true,
                  color: "error",
                  msg: error.response.data.message,
                });
                if (error.response.status == 422) {
                  let obj = error.response.data.errors;
                  for (let [key, customer] of Object.entries(obj)) {
                    this.server_errors[key] = customer[0];
                  }
                }
              });
        } catch (error) {
          this.$store.commit("Toast_State", {
            value: true,
            color: "error",
            msg: 'ເກີດຂໍ້ຜິດພາດບາງຢ່າງ',
          });
        } finally {
          this.loading = false;
        }
      }
    },
    async fetchData() {
      this.$store.commit("Loading_State", true);
      await this.$axios
          .get("admin/list-wallet-transfer-types", {
            params: queryOption([
              {page: this.pagination.current_page},
              {per_page: this.per_page},
            ]),
          })
          .then((res) => {
            if (res.data.code == 200) {
              this.loading = false;
              this.$store.commit("Loading_State", false);
              this.data = res.data.data.data;
              this.pagination = res.data.data.pagination;
            }
          })
          .catch((error) => {
            this.$store.commit("Loading_State", false);
            if (error.response.status == 422) {
              let obj = error.response.data.errors;
              for (let [key, message] of Object.entries(obj)) {
                this.server_errors[key] = message[0];
              }
            }
          });
    },

    closeAddModal() {
      this.$store.commit("modalAdd_State", false);
    },
    OpenModalEdit(item) {
      this.edit_data = item;
      this.$store.commit("modalEdit_State", true);
    },
    async updateItem() {
      const data = {
        name: this.edit_data.name,
        short_name: this.edit_data.short_name,
        type: this.edit_data.type,
      }
      if (this.$refs.form.validate() == true) {
        try {
          this.loading = true;
          await this.$axios
              .put(`admin/edit-wallet-transfer-type/${this.edit_data.id}`, data).then((res) => {
                if (res.data.code == 200) {
                  this.closeUpdateModal();
                  this.fetchData();
                  this.reset();
                  this.$store.commit("Toast_State", {
                    value: true,
                    color: "success",
                    msg: res.data.message,
                  });
                }
              }).catch((error) => {
                this.loading = false;
                this.$store.commit("Toast_State", {
                  value: true,
                  color: "error",
                  msg: error.response.data.message,
                });
                if (error.response.status == 422) {
                  let obj = error.response.data.errors;
                  for (let [key, message] of Object.entries(obj)) {
                    this.server_errors[key] = message[0];
                  }
                }
              });
        } catch (error) {
          this.$store.commit("Toast_State", {
            value: true,
            color: "error",
            msg: 'ເກີດຂໍ້ຜິດພາດບາງຢ່າງ',
          });
        } finally {
          this.loading = false;
        }
      }
    },
    closeUpdateModal() {
      this.$store.commit("modalEdit_State", false);
    },

    closeDelete() {
      this.$store.commit("modalDelete_State", false);
    },

    deleteItem(id) {
      this.dataId = id;
      this.$store.commit("modalDelete_State", true);
    },

    async deleteItemConfirm() {
      try {
        this.loading = true;
        await this.$axios
            .delete(`admin/delete-wallet-transfer-type/${this.dataId}`)
            .then((res) => {
              if (res.data.code == 200) {
                this.fetchData();
                this.$store.commit("Toast_State", {
                  value: true,
                  color: "success",
                  msg: res.data.message,
                });
                this.loading = false;
                this.$store.commit("modalDelete_State", false);
              }
            })
            .catch((error) => {
              this.$store.commit("Toast_State", {
                value: true,
                color: "error",
                msg: error.response.data.message,
              });
              this.$store.commit("modalDelete_State", false);
              this.loading = false;
            });
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }

    },
    reset() {
      this.$refs.form.reset();
    },
    AddUser(id) {
      this.$router.push({name: 'department-user', params: {"id": id}});
    },
    Search() {
      GetOldValueOnInput(this);
    },
  },
  watch: {
    "formState.name": function () {
      this.server_errors.name = "";
    },
    "formState.short_name": function () {
      this.server_errors.short_name = "";
    },
    "selectedType": function () {
      this.server_errors.type = "";
    },

    "edit_data.name": function () {
      this.server_errors.name = "";
    },
    "edit_data.short_name": function () {
      this.server_errors.short_name = "";
    },
    "edit_data.type": function () {
      this.server_errors.type = "";
    },
    search: function (value) {
      if (value == "") {
        this.fetchData();
      }
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style lang="scss">
@import "../../../public/scss/main.scss";
</style>
