import firebase from 'firebase';

export default {
    data() {
        return {
            loading: false,
            transactionLoading: false,
            btnVerify: false,
            requestLoading:false,
            wallets: [],
            transactionType: [],
            transferTypes: [],
            selectedWallet: "",
            selectedTransactionType: "",
            selectedTransferType: "",
            dataId: "",
            server_errors: {
                file:"",
                account_name: "",
                account_number: "",
                description: "",
                point: "",
                phone: "",
                name: "",
                otp:""
            },
            filename: null,
            //Pagination
            successData:{},
            departments: [],
            selectedType: "department",
            departmentTypes: [
                {
                    value: 'department',
                    name: "ພະແນກ",
                },
                {
                    value: 'customer',
                    name: "Customer",
                }, {
                    value: 'employee',
                    name: "ພະນັກງານ",
                },
            ],
            selectedOwner: "",
            appVerifier: "",
            phone: "",
            transactions: [],
            code: "",
            otp: "",
            //Validation
            walletRule: [(v) => !!v || "From Wallet is required"],
            transactionRule: [(v) => !!v || "Transaction Type is required"],
            transferRule: [(v) => !!v || "Transfer Type is required"],
            rulesFile: [(v) => !!v || "File is required"],
        };
    },
    methods: {
        joinData(data) {
            if (data) {
                return data.join(", ");
            }
        },
        clearErrors() {
            this.server_errors.account_name = '';
            this.server_errors.account_number = '';
            this.server_errors.description = '';
            this.server_errors.point = '';
            this.server_errors.phone = '';
            this.server_errors.name = '';
            this.server_errors.from_wallet_id = '';
            this.server_errors.file = '';

        },
        openAddModal() {
            this.$store.commit("modalAdd_State", true);
        },
        closeAddModal() {
            window.location.reload();
            this.$store.commit("modalAdd_State", false);
        },
        closeViewModal() {
            this.$store.commit("modalView_State", false);
            this.$router.push({name: "transaction-points"});
        },
        async AddItem() {
            console.log(this.filename)
            if (this.$refs.form.validate() == true) {
                try {
                    let formData = new FormData();
                    formData.append("file", this.filename);
                    formData.append("from_wallet_id", this.selectedWallet);
                    formData.append("transfer_type_id", this.selectedTransferType);
                    this.transactionLoading = true;
                    await this.$axios
                        .post("admin/validation-wallet-transaction-point", formData).then((res) => {
                            if (res.data.code == 200) {
                                this.transactions = res.data.data;
                                localStorage.setItem('file', JSON.stringify(this.filename.name));
                                localStorage.setItem('from_wallet', JSON.stringify(this.selectedWallet));
                                localStorage.setItem('transfer_type', JSON.stringify(this.selectedTransferType));
                                localStorage.setItem('listTransactions', JSON.stringify(this.transactions));
                                // this.$emit("onTransfer", {
                                //     from_wallet_id: this.selectedWallet,
                                // });
                                this.$router.push({name: "transaction-points-preview"});
                                // this.requestOtp();
                            }
                        }).catch((error) => {
                            this.transactionLoading = false;
                            this.clearErrors();
                            this.$store.commit("Toast_State", {
                                value: true,
                                color: "error",
                                msg: error.response.data.message,
                            });
                            if (error.response.status == 422) {
                                let obj = error.response.data.errors;
                                for (let [key, result] of Object.entries(obj)) {
                                    this.server_errors[key] = result;
                                }
                            }
                        });
                } catch (error) {
                    this.transactionLoading = false;
                } finally {
                    this.transactionLoading = false;
                }
            }
        },
        verifyOtp() {
            this.initReCaptcha();
            this.btnVerify = true;
            let code = this.otp;
            window.confirmationResult.confirm(code).then((res) => {
                // User signed in successfully.
                if (res) {
                    this.btnVerify = false;
                    const token = (res.user._lat);
                    this.transactionPoint(token);
                }
            }).catch((error) => {
                this.btnVerify = false;
                this.server_errors.otp = error.message;
                     this.store.commit("Toast_State", {
                         value: true,
                         color: "error",
                         msg: error.message,
                     });
            });
        },

        async transactionPoint(id_token) {
            const from_wallet_id = localStorage.getItem('from_wallet');
            const from_id = JSON.parse(from_wallet_id);
            const transferType = localStorage.getItem('transfer_type');
            const transfer_type_id = JSON.parse(transferType);
            const point = localStorage.getItem('listTransactions');
            const transactionPoints = JSON.parse(point);
            try {
                const formData = {
                    from_wallet_id: from_id,
                    transfer_type_id: transfer_type_id,
                    transactions: transactionPoints,
                }
                this.loading = true;
                await this.$axios
                    .post("admin/wallet-transaction-point",formData,{headers: {idToken: id_token}}).then((res) => {
                        if (res.data.code == 200) {
                            this.successData = res.data.data;
                            localStorage.removeItem('id_token');
                            this.$store.commit("Toast_State", {
                                value: true,
                                color: "success",
                                msg: res.data.message,
                            });
                            this.clearLocalStorage();
                            this.$store.commit("modalAdd_State", false);
                            this.$store.commit("modalView_State", true);
                        }
                    }).catch((error) => {
                        this.clearErrors();
                        this.loading = false;
                        this.$store.commit("Toast_State", {
                            value: true,
                            color: "error",
                            msg: error.response.data.message,
                        });
                        if (error.response.status == 422) {
                            let obj = error.response.data.errors;
                            for (let [key, result] of Object.entries(obj)) {
                                this.server_errors[key] = result;
                            }
                        }
                    });
            } catch (error) {
                this.loading = false;
            } finally {
                this.loading = false;
            }
        },
        clearLocalStorage(){
            localStorage.removeItem('file');
            localStorage.removeItem('from_wallet');
            localStorage.removeItem('transfer_type',);
            localStorage.removeItem('listTransactions',);
        },

        fetchWallet() {
            this.$store.commit("Loading_State", true);
            this.$axios
                .get("admin/list-wallets")
                .then((res) => {
                    if (res.data.code == 200) {
                        this.loading = false;
                        this.$store.commit("Loading_State", false);
                        this.wallets = res.data.data;
                    }
                })
                .catch((error) => {
                    this.$store.commit("Loading_State", false);
                    if (error.response.status == 422) {
                        let obj = error.response.data.errors;
                        for (let [key, message] of Object.entries(obj)) {
                            this.server_errors[key] = message[0];
                        }
                    }
                });
        },

        fetchTransferType() {
            this.$store.commit("Loading_State", true);
            this.$axios
                .get("admin/list-wallet-transfer-types")
                .then((res) => {
                    if (res.data.code == 200) {
                        this.loading = false;
                        this.$store.commit("Loading_State", false);
                        this.transferTypes = res.data.data;
                    }
                })
                .catch((error) => {
                    this.$store.commit("Loading_State", false);
                    if (error.response.status == 422) {
                        let obj = error.response.data.errors;
                        for (let [key, message] of Object.entries(obj)) {
                            this.server_errors[key] = message[0];
                        }
                    }
                });
        },

        async fetchTransactionType() {
            this.$store.commit("Loading_State", true);
            await this.$axios
                .get("admin/list-wallet-transaction-types")
                .then((res) => {
                    if (res.data.code == 200) {
                        this.loading = false;
                        this.transactionType = res.data.data;
                    }
                })
                .catch((error) => {
                    this.$store.commit("Loading_State", false);
                    if (error.response.status == 422) {
                        let obj = error.response.data.errors;
                        for (let [key, message] of Object.entries(obj)) {
                            this.server_errors[key] = message[0];
                        }
                    }
                });
        },
        showText(txt) {
            if (txt) {
                return txt.name + `(${txt.short_name})`;
            } else return txt.name + `(${txt.short_name})`;
        },
        back() {
            this.clearLocalStorage();
            this.$router.go(-1);
        },

        reset() {
            this.$refs.form.reset();
        },
        initReCaptcha() {
            window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
                "recaptcha-container",
                {
                    size: "invisible",
                    "expired-callback": function () {
                    },
                }
            );
            //
            this.appVerifier = window.recaptchaVerifier;
        },
    },
    watch: {
        "filename": function () {
            this.server_errors.file = "";
            this.clearErrors();
        },
        selectedWallet: function (value) {
            this.server_errors.from_wallet_id = "";
            const item = this.wallets.find(item => item.id === value);
            this.phone = item.phone;
        },
        "selectedTransferType": function () {
            this.server_errors.transfer_type_id = "";
        },
        otp:function () {
            this.server_errors.otp = "";
        }
    },
}