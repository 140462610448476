<template>
  <v-container>
    <v-card class="elevation-0">
      <v-card-text>
        <v-container>
          <h2 class="text-center display-5 black--text mb-0 mt-4">
            Verify Code OTP
          </h2>
          <p class="text-center display-5 black--text mb-8 mt-0">Input Code from SMS</p>
          <v-form ref="form" lazy-validation>
            <v-row>
             <v-col cols="xl-6 lg-6 md-12 sm-12 xm-12">
               <v-otp-input
                   ref="otpInput"
                   input-classes="otp-input"
                   separator=""
                   :num-inputs="6"
                   :should-auto-focus="true"
                   :is-input-num="true"
                   @on-complete="handleOnComplete"
                   class="otp"
                   v-model="otp"
               />
               <v-btn text @click="handleClearInput()" small style="margin:auto;">Clear</v-btn>
             </v-col>
            </v-row>
          </v-form>
          <p>{{otp}}</p>
        </v-container>
        <v-card-actions>
          <v-btn
              color="darken-0"
              class="btn-primary"
              :loading="btnVerify"
              :disabled="btnVerify"
              @click="requestOtp"
          >
            Resend TOP
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              color="darken-0"
              class="btn-primary"
              :loading="btnVerify"
              :disabled="btnVerify"
              @click="verifyOtp"
          >
            Confirm TOP
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
    <div id="recaptcha-container"></div>
  </v-container>
</template>

<script>
import transaction_point from "@/mixin/transaction_point";

export default {
  name: "DepartmentView",
  mixins: [transaction_point],
  title() {
    return `Confirm TOP|${this.title}`;
  },
};
</script>

<style lang="scss">
@import "../../../public/scss/main.scss";


.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;

  &.error {
    border: 1px solid red !important;
  }
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  //.otp{
  //  padding: 4px 4px;
  //  margin-left: -64px;
  //}
  .otp-input {
    width: 30px;
    height: 30px;
    padding: 3px;
    margin: 0 5px;
    font-size: 18px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;

    &.error {
      border: 1px solid red !important;
    }
  }
}
</style>
