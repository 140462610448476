import { render, staticRenderFns } from "./AdminView.vue?vue&type=template&id=eb8a67e8&"
import script from "./AdminView.vue?vue&type=script&lang=js&"
export * from "./AdminView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContent } from 'vuetify/lib/components/VContent';
installComponents(component, {VContent})
