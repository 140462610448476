<template>
  <div>
    <SidebarView />
    <v-content>
      <router-view />
      <SnackbarView />
      <CheckToken/>
      <LoadingView />
    </v-content>
  </div>
</template>
<script>
import SnackbarView from "@/components/toast/SnackbarView.vue";
import CheckToken from "@/components/toast/CheckToken.vue";
import LoadingView from "@/components/loadingView.vue";
import SidebarView from "@/components/layouts/SidebarView.vue";
export default {
  components: {
    SidebarView,
    SnackbarView,
    LoadingView,
  CheckToken
  },
};
</script>
<style lang="scss">
</style>