<template>
  <v-app>
      <AdminView v-if="$route.meta.layout === 'admin'" />
      <NoneView v-if="$route.meta.layout === 'none'" />
      <HomeView v-if="$route.meta.layout === 'home'" />
  </v-app>
</template>

<script>
import AdminView from "@/components/layouts/AdminView.vue";
import NoneView from "@/components/layouts/NoneView.vue";
import HomeView from "@/components/layouts/Home/HomeView.vue";
// import HomeView from "@/components/layouts/NavbarView.vue";
export default {
  name: 'App',
  components: {
    AdminView,
    HomeView,
    NoneView,
  },
  data: () => ({
    //
  }),
};
</script>

<style>
@import "../public/css/style.css";
@import "../public/css/font.css";
@import "../public/scss/main.scss";
</style>
