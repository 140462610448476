<template>
  <v-container>
    <div id="recaptcha-container"></div>
    <v-card class="elevation-0">
      <v-card-title>
        <p>ໂອນພ້ອຍ</p>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                    v-model="selectedWallet"
                    :items="wallets"
                    item-text="account_name"
                    item-value="id"
                    label="ບັນຊີຕົ້ນທາງ"
                    dense
                    outlined
                    :rules="walletRule"
                ></v-autocomplete>
                <p class="errors">
                  <v-icon small class="primary-color" v-if="server_errors.from_wallet_id">mdi-arrow-right</v-icon>
                  {{ joinData(server_errors.from_wallet_id) }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                    v-model="selectedTransferType"
                    :items="transferTypes"
                    :item-text="showText"
                    item-value="id"
                    label="ປະເພດການໂອນ"
                    dense
                    outlined
                    :rules="transferRule"
                ></v-autocomplete>
                <p class="errors">
                  <v-icon small class="primary-color" v-if="server_errors.transfer_type_id">mdi-arrow-right</v-icon>
                  {{ joinData(server_errors.transfer_type_id) }}
                </p>
              </v-col>
            </v-row>
            <v-row>
<!--              <v-col>-->
<!--                <v-file-input-->
<!--                    v-model="filename"-->
<!--                    counter-->
<!--                    label="File ບັນຊີການໂອນ"-->
<!--                    multiple-->
<!--                    accept=".xlsx"-->
<!--                    placeholder="Select your files"-->
<!--                    prepend-icon="mdi-paperclip"-->
<!--                    ref="filename"-->
<!--                    outlined-->
<!--                    dense-->
<!--                    :show-size="1000"-->
<!--                >-->
<!--                  <template v-slot:selection="{ index, text }">-->
<!--                    <v-chip-->
<!--                        v-if="index < 2"-->
<!--                        dark-->
<!--                        label-->
<!--                        small-->
<!--                    >-->
<!--                      {{ text }}-->
<!--                    </v-chip>-->

<!--                    <span-->
<!--                        v-else-if="index === 2"-->
<!--                        class="text-overline grey&#45;&#45;text text&#45;&#45;darken-3 mx-2"-->
<!--                    >-->
<!--        +{{ filename.length - 2 }} File(s)-->
<!--      </span>-->
<!--                  </template>-->
<!--                </v-file-input>-->
<!--                <p class="errors">-->
<!--                  {{ server_errors.file }}-->
<!--                </p>-->
<!--              </v-col>-->

              <v-col cols="12">
                <v-file-input
                    label="ໄຟລບັນຊີປາຍທາງ (xlsx)"
                    show-size
                    accept=".xlsx,.xls"
                    placeholder="Select your files"
                    truncate-length="30"
                    :rules="rulesFile"
                    ref="filename"
                    v-model="filename"
                    outlined
                    dense
                ></v-file-input>
                <p class="errors">
                  <v-icon small class="primary-color" v-if="server_errors.file">mdi-arrow-right</v-icon>
                  {{ joinData(server_errors.file) }}
                </p>
                <p class="errors">
                  <v-icon small class="primary-color" v-if="server_errors.account_name">mdi-arrow-right</v-icon>
                  {{ joinData(server_errors.account_name) }}
                </p>
                <p class="errors">
                  <v-icon small class="primary-color" v-if="server_errors.account_number">mdi-arrow-right</v-icon>
                  {{ joinData(server_errors.account_number) }}
                </p>
                <p class="errors">
                  <v-icon small class="primary-color" v-if="server_errors.point">mdi-arrow-right</v-icon>
                  {{ joinData(server_errors.point) }}
                </p>
                <p class="errors">
                  <v-icon small class="primary-color" v-if="server_errors.description">mdi-arrow-right</v-icon>
                  {{ joinData(server_errors.description) }}
                </p>
                <p class="errors">
                  <v-icon small class="primary-color" v-if="server_errors.phone">mdi-arrow-right</v-icon>
                  {{ joinData(server_errors.phone) }}
                </p>
                <p class="errors">
                  <v-icon small class="primary-color" v-if="server_errors.name">mdi-arrow-right</v-icon>
                  {{ joinData(server_errors.name) }}
                </p>
              </v-col>

            </v-row>
            <!--            <v-row>-->
            <!--              <v-col cols="12">-->
            <!--                <v-autocomplete-->
            <!--                    v-model="selectedTransactionType"-->
            <!--                    :items="transactionType"-->
            <!--                    item-text="name"-->
            <!--                    item-value="id"-->
            <!--                    label="Transaction Type"-->
            <!--                    dense-->
            <!--                    :rules="transactionRule"-->
            <!--                ></v-autocomplete>-->
            <!--                <p class="errors">-->
            <!--                  {{ server_errors.transaction_type_id }}-->
            <!--                </p>-->
            <!--              </v-col>-->
            <!--            </v-row>-->
          </v-form>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="btn-info mr-4" text @click="back()">
            ຍົກເລີກ
          </v-btn>
          <v-btn
              class="btn-primary mr-4"
              text
              :loading="transactionLoading"
              :disabled="transactionLoading"
              @click="AddItem()"
          >
            ອັບໂຫຼດ
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import transaction_point from "@/mixin/transaction_point";
export default {
  name: "DepartmentView",
  mixins: [transaction_point],
  title() {
    return `TransactionPoint| Hal Point`;
  },
  created() {
    this.fetchWallet();
    this.fetchTransferType();
    this.fetchTransactionType();
  },
};
</script>

<style lang="scss">
@import "../../../public/scss/main.scss";
</style>
