import Vue from 'vue'
import VueRouter from 'vue-router'
import Middlewares from '@/Middlewares/Index'
import DashboardView from '../views/dashboard/DashboardView.vue'
import DepartmentView from '../views/department/DepartmentView'
import DepartmentEmployeeView from '../views/employees/EmployeeView'
import DepartmentUser from '../views/departmentUser/DepartmentUser'
import TransactionStatus from '../views/transactionStatus/transactionStatus'
import TransferType from '../views/transferType/transferType'
import WalletView from '../views/wallet/walletView'
import AddPoint from '../views/wallet/addPoint'
import WalletViewDetail from '../views/wallet/walletViewDetail'
import TransactionPoint from '../views/transactionPoint/transactionPointView'
import TransactionPointDetail from '../views/transactionPoint/transactionPointViewDetail'
import TransactionPointAdd from '../views/transactionPoint/transactionPointCreate'
import TransactionPointPreview from '../views/transactionPoint/transactionPointPreveiw'
import VerifyOtp from '../views/transactionPoint/verifyOtpPoint'
// import walletView from "@/views/wallet/walletView";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: DashboardView,
        meta: {
            layout: "admin",
            middleware: [Middlewares.auth],
            breadcrumb: [
                {
                    text: 'Dashboard',
                    disabled: true,
                    href: '/'
                }
            ]
        }
    },
    {
        path: '/department',
        name: 'department',
        component: DepartmentView,
        meta: {
            layout: "admin",
            middleware: [Middlewares.auth],
            breadcrumb: [
                {
                    text: 'Department',
                    disabled: true,
                    href: '/'
                }
            ]
        }
    },
    {
        path: '/department-user/:id',
        name: 'department-user',
        component: DepartmentUser,
        meta: {
            layout: "admin",
            middleware: [Middlewares.auth],
            breadcrumb: [
                {
                    text: 'Department',
                    disabled: false,
                    href: '/department'
                },
                {
                    text: 'User',
                    disabled: true,
                    href: '/'
                }
            ]
        }
    },
    {
        path: '/transfer-type',
        name: 'transfer-type',
        component: TransferType,
        meta: {
            layout: "admin",
            middleware: [Middlewares.auth],
            breadcrumb: [
                {
                    text: 'Transfer Type',
                    disabled: true,
                    href: '/'
                }
            ]
        }
    },
    {
        path: '/transaction-status',
        name: 'transaction-status',
        component: TransactionStatus,
        meta: {
            layout: "admin",
            middleware: [Middlewares.auth],
            breadcrumb: [
                {
                    text: 'Transaction Status',
                    disabled: true,
                    href: '/'
                }
            ]
        }
    },
    {
        path: '/wallet',
        name: 'wallet',
        component: WalletView,
        meta: {
            layout: "admin",
            middleware: [Middlewares.auth],
            breadcrumb: [
                {
                    text: 'Wallet',
                    disabled: true,
                    href: '/'
                }
            ]
        }
    },
    {
        path: '/wallet/:id',
        name: 'wallet-detail',
        component: WalletViewDetail,
        meta: {
            layout: "admin",
            middleware: [Middlewares.auth],
            breadcrumb: [
                {
                    text: 'Wallet',
                    disabled: false,
                    href: '/wallet'
                },
                {
                    text: 'ປະຫວັດການໂອນພ້ອຍ',
                    disabled: true,
                    href: '/'
                }
            ]
        }
    },
    {
        path: '/add/point',
        name: 'add-point',
        component: AddPoint,
        meta: {
            layout: "admin",
            middleware: [Middlewares.auth],
            breadcrumb: [
                {
                    text: 'Wallet',
                    disabled: false,
                    href: '/wallet'
                },
                {
                    text: 'Add Point',
                    disabled: true,
                    href: '/'
                }
            ]
        }
    },
    {
        path: '/transaction-points',
        name: 'transaction-points',
        component: TransactionPoint,
        meta: {
            layout: "admin",
            middleware: [Middlewares.auth],
            breadcrumb: [
                {
                    text: 'Transaction Point',
                    disabled: true,
                    href: '/'
                }
            ]
        }
    },
    {
        path: '/transaction-points/transfer',
        name: 'transaction-points-add',
        component: TransactionPointAdd,
        meta: {
            layout: "admin",
            middleware: [Middlewares.auth],
            breadcrumb: [
                {
                    text: 'Transaction Point',
                    disabled: true,
                    href: '/transaction-points'
                },
                {
                    text: 'transfer',
                    disabled: true,
                    href: '/'
                }
            ]
        }
    },
    {
        path: '/transaction-points/transfer/preview',
        name: 'transaction-points-preview',
        component: TransactionPointPreview,
        meta: {
            layout: "admin",
            middleware: [Middlewares.auth],
            breadcrumb: [
                {
                    text: 'Transaction Point',
                    disabled: true,
                    href: '/transaction-points'
                },
                {
                    text: 'Transfer',
                    disabled: true,
                    href: '/transaction-points/transfer'
                },
                {
                    text: 'Preview',
                    disabled: true,
                    href: '/'
                }
            ]
        }
    },
    {
        path: '/transaction-points/:id',
        name: 'transaction-points-detail',
        component: TransactionPointDetail,
        meta: {
            layout: "admin",
            middleware: [Middlewares.auth],
            breadcrumb: [
                {
                    text: 'Transaction Point',
                    disabled: false,
                    href: '/transaction-points'
                },
                {
                    text: 'Detail',
                    disabled: true,
                    href: '/'
                }
            ]
        }
    },
    {
        path: '/verify-otp-points',
        name: 'verify-otp-points',
        component: VerifyOtp,
        meta: {
            layout: "admin",
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/department-employees',
        name: 'department-employees',
        component: DepartmentEmployeeView,
        meta: {
            layout: "admin",
            middleware: [Middlewares.auth],
            breadcrumb: [
                {
                    text: 'User',
                    disabled: true,
                    href: '/department-employees'
                }
            ]
        }
    },

    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/auth/LoginView.vue'),
        meta: {
            layout: "none",
            middleware: [Middlewares.guest],
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


function nextCheck(context, middleware, index) {
    const nextMiddleware = middleware[index];
    if (!nextMiddleware) return context.next();

    return (...parameters) => {
        context.next(...parameters);
        const nextMidd = nextCheck(context, middleware, index + 1);

        nextMiddleware({...context, nextMidd});
    }
}

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
        const ctx = {
            from,
            next,
            router,
            to
        }
        const nextMiddleware = nextCheck(ctx, middleware, 1);
        return middleware[0]({...ctx, nextMiddleware});

    }
    return next();
});

export default router
