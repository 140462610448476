<template>
  <div>
    <v-app-bar flat fixed app class="nav">
      <v-icon style="padding: 4px" @click="drawer = !drawer">
        mdi-order-bool-descending
      </v-icon>

      <v-breadcrumbs divider=">" :items="$route.meta.breadcrumb">
      </v-breadcrumbs>

      <v-spacer></v-spacer>
<!--      <v-menu bottom min-width="200px" rounded offset-y>-->
<!--        <template v-slot:activator="{ on }">-->
<!--          <v-btn text fab dark small class="mr-8" v-on="on">-->
<!--            <v-badge overlap color="orange">-->
<!--              <template v-slot:badge>{{ notifications.length }}</template>-->
<!--              <v-icon large color="grey darken-1"> notifications </v-icon>-->
<!--            </v-badge>-->
<!--          </v-btn>-->
<!--        </template>-->
<!--        <v-card>-->
<!--          <v-card-text class="px-0">-->
<!--            <v-list-item-content class="justify-center">-->
<!--              <v-list-->
<!--                two-line-->
<!--                style="max-height: 650px"-->
<!--                class="overflow-y-auto"-->
<!--              >-->
<!--                <v-list-item-group active-class="pink&#45;&#45;text" multiple>-->
<!--                  <template v-for="(item, index) in notifications">-->
<!--                    <v-list-item :key="item.id" @click="viewPage(item.id)">-->
<!--                      <v-list-item-content>-->
<!--                        <v-list-item-title-->
<!--                          v-text="item.data.name"-->
<!--                        ></v-list-item-title>-->

<!--                        <v-list-item-subtitle-->
<!--                          class="text&#45;&#45;primary"-->
<!--                          v-text="item.data.text"-->
<!--                        ></v-list-item-subtitle>-->

<!--                        <v-list-item-subtitle-->
<!--                          v-text="item.data.thanks"-->
<!--                        ></v-list-item-subtitle>-->
<!--                      </v-list-item-content>-->

<!--                      <v-list-item-action-text>-->
<!--                        <span class="primary-color">{{-->
<!--                          moment(item.created_at).format("DD-MM-YY")-->
<!--                        }}</span></v-list-item-action-text-->
<!--                      >-->
<!--                    </v-list-item>-->

<!--                    <v-divider-->
<!--                      v-if="index < notifications.length - 1"-->
<!--                      :key="index"-->
<!--                    ></v-divider>-->
<!--                  </template>-->
<!--                </v-list-item-group>-->
<!--              </v-list>-->
<!--              <v-divider></v-divider>-->
<!--              <v-list-item @click="viewAllNoti">-->
<!--                <div class="mx-auto text-center">-->
<!--                  <span class="primary-color">ອ່ານທັງໝົດ</span>-->
<!--                </div>-->
<!--              </v-list-item>-->
<!--            </v-list-item-content>-->
<!--          </v-card-text>-->
<!--        </v-card>-->
<!--      </v-menu>-->

      <v-menu bottom min-width="200px" rounded offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on">
            <v-avatar color="grey" size="46px">
              <span class="white--text text-h5 text-break">{{ name }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar color="grey" size="46px">
                <span class="white--text text-h5 text-break">{{ name }}</span>
              </v-avatar>
<!--              <h4>{{ userProfile.name }}</h4>-->
<!--              <p class="text-caption mt-1" v-if="userProfile.credential">{{ userProfile.credential }}<br /></p>-->
              <v-divider class="my-3"></v-divider>
              <v-btn depressed rounded text @click="userLogout">
                Log out
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      app
      floating
      class="app-navigation-menu"
    >
      <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
        <router-link to="/" class="d-flex align-center text-decoration-none">
          <v-img
            :src="require('../../assets/logo.png')"
            max-height="60px"
            max-width="60px"
            alt="logo"
            contain
            eager
            class="app-logo me-3"
          ></v-img>
          <v-slide-x-transition>
            <h3 class="app-title text--primary">HAL Wallet</h3>
          </v-slide-x-transition>
        </router-link>
      </div>

      <v-list expand shaped>
        <v-list-item
            :to="{name:'dashboard'}"
            link
            router
            exact
            color="indigo"
            v-if="$can(['supermanager','departmentmanager'])"
        >
            <v-list-item-action>
              <v-icon>mdi-home</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>ໜ້າຫຼັກ</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item
            :to="{name:'wallet'}"
            link
            router
            exact
            color="indigo"
            v-if="$can(['supermanager','departmentmanager'])"
        >
          <v-list-item-action>
            <v-icon>mdi-wallet</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>ກະເປົາ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            :to="{name:'transaction-points'}"
            link
            router
            exact
            color="indigo"
            v-if="$can(['supermanager','departmentmanager'])"
        >
          <v-list-item-action>
            <v-icon>mdi-credit-card</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>ການເຮັດທຸລະກຳ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            :to="{name:'department'}"
            link
            router
            exact
            color="indigo"
            v-if="$can(['supermanager'])"
        >
          <v-list-item-action>
            <v-icon>mdi-layers</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>ຂໍ້ມູນພະແນກ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            :to="{name:'department-employees'}"
            link
            router
            exact
            color="indigo"
            v-if="$can(['supermanager','departmentmanager'])"
        >
          <v-list-item-action>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>ຂໍ້ມູນ User</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            :to="{name:'transaction-status'}"
            link
            router
            exact
            color="indigo"
            v-if="$can(['supermanager'])"
        >
          <v-list-item-action>
            <v-icon>mdi-application-cog</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>ສະຖານະການເຮັດທຸລະກຳ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            :to="{name:'transfer-type'}"
            link
            router
            exact
            color="indigo"
            v-if="$can(['supermanager'])"
        >
          <v-list-item-action>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>ປະເພດການໂອນ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      drawer: true,
      userProfile: {},
      name: "",
      //Pagination
      offset: 12,
      pagination: {},
      per_page: 20,
      notifications: [],
      selectedStatus: "unread",
      items: [
        {
          icon: "mdi-home",
          title: "ໜ້າຫຼັກ",
          to: "/",
          permissions: ["add-ipoint","edit-ipoint","delete-ipoint","view-ipoint"],
          roles:['supermanager'],
        },
        {
          icon: "mdi-briefcase",
          title: "Wallet",
          to: "/wallet",
          permissions: ["add-ipoint","edit-ipoint","delete-ipoint","view-ipoint"],
          roles:['supermanager'],
        },
        {
          icon: "mdi-apps",
          title: "Transaction Points",
          to: "/transaction-points",
          permissions: ["add-ipoint","edit-ipoint","delete-ipoint","view-ipoint"],
          roles:['supermanager'],
        },
        {
          icon: "mdi-layers",
          title: "ຂໍ້ມູນພະແນກ",
          to: "/department",
          permissions: ["add-ipoint","edit-ipoint","delete-ipoint","view-ipoint"],
          roles:['supermanager'],
        },
        {
          icon: "mdi-account-group",
          title: "ຂໍ້ມູນພະນັກງານ",
          to: "/department-employees",
          permissions: ["add-ipoint","edit-ipoint","delete-ipoint","view-ipoint"],
          roles:['supermanager'],
        },
        {
          icon: "mdi-application-cog",
          title: "Transaction Status",
          to: "/transaction-status",
          permissions: ["add-ipoint","edit-ipoint","delete-ipoint","view-ipoint"],
          roles:['supermanager'],
        },
        {
          icon: "mdi-cog",
          title: "Transfer Type",
          to: "/transfer-type",
          permissions: ["add-ipoint","edit-ipoint","delete-ipoint","view-ipoint"],
          roles:['supermanager'],
        },
      ],
      group_menu: [
        {
          title: "ຈັດການຂໍ້ມູນ",
          icon: "mdi-cog",
          group_permissions: [
            "get_role",
            "get_user",
            "get_customer",
            "get_driver",
            "create_address",
          ],
          roles:['supermanager'],
          menu: [
            {
              icon: "mdi-file-import",
              title: "Import User",
              to: "/import-file",
              permissions: ["create_driver"],
            },
            {
              icon: "mdi-account-key",
              title: "Roles",
              to: "/role",
              permissions: ["get_role", "create_role"],
            },
          ],
        },
      ],
    };
  },

  computed: {
    user() {
      return this.$store.getters["User/getUsertype"];
    },
    // profile() {
    //   return this.$store.getters["User/getUserProfile"];
    // },
    ...mapGetters({
      userRole: "User/getUserRole",
    }),
  },
  created() {
    this.userProfile = JSON.parse(window.localStorage.getItem("authUser"));
    this.name = this.userProfile.credential.slice(0, 1);
    // console.log(this.userProfile)
    // this.fetchData();
  },
  methods: {
    ...mapActions({
      Logout: "User/destroyToken",
    }),
    userLogout() {
      this.$store.dispatch('auth/destroyToken');
    },
  },
};
</script>

<style lang="scss" scoped>
.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 28px !important;
}
.nav {
  border-bottom: 1px solid #eee;
  background: #fff !important;
}
.theme--light.v-navigation-drawer {
  border-right: 1px solid #eee !important;
}
.v-main__wrap.v-content__wrap {
  background: #eee !important;
}
.v-main__wrap.v-content__wrap {
  background: #eee !important;
}

</style>
