<template>
  <v-container>
    <v-row class="mb-n6">
      <v-col>
        <v-btn @click="openAddModal()" class="btn-primary elevation-0"
        >
          <v-icon>mdi-plus</v-icon> ເພີມ User
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <v-card class="pa-2">
          <v-card-title>
            ຂໍ້ມູນ ຜູ້ໃຊ້ ໃນພະແນກ ({{ pagination.total }})
            <v-divider class="mx-4" vertical></v-divider>
            <v-spacer></v-spacer>
            <!--
            <v-select
              outlined
              dense
              :items="statuses"
              v-model="selectedStatus"
              item-text="name"
              item-value="name"
              label="ສະຖານະ"
            ></v-select>
            <v-spacer></v-spacer>
                        -->
            <v-text-field
                v-model="search"
                clearable
                prepend-inner-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                @keyup.enter="Search()"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="data"
              :search="search"
              :disable-pagination="true"
              hide-default-footer
          >
            <template v-slot:[`item.created_at`]="{ item }">
              {{ moment(item.created_at).format("hh:mm DD-MM-YYYY") }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <!--  <v-icon small class="mr-2"> mdi-key </v-icon> -->
              <v-icon small class="mr-2" @click="OpenModalEdit(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item.id)"> mdi-delete</v-icon>
            </template>
          </v-data-table>
          <br/>
          <template>
            <Pagination
                v-if="pagination.total_pages > 1"
                :pagination="pagination"
                :offset="offset"
                @paginate="fetchData()"
            ></Pagination>
          </template>
        </v-card>
      </v-col>
    </v-row>

    <!-- Modal Add-->
    <ModalAdd>
      <template @close="close">
        <v-card>
          <v-card-title>
            <p>ເພີ່ມ ຜູ້ໃຊລະບົບ ພະແນກ</p>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="ຊື່"
                        required
                        v-model="formState.name"
                        :rules="nameRule"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.name }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Email"
                        required
                        v-model="formState.email"
                        :rules="emailRules"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.email }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Password *"
                        type="password"
                        v-model="formState.password"
                        :rules="passwordRules"
                        required
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.password }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                        v-model="selectedRole"
                        :items="roleTypes"
                        item-text="name"
                        item-value="id"
                        label="Role"
                        dense
                        :rules="roleRules"
                    ></v-autocomplete>
                    <p class="errors">
                      {{ server_errors.role_id }}
                    </p>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeAddModal()">
                ຍົກເລີກ
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="AddItem()"
              >
                ບັນທຶກ
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalAdd>

    <!--    Edit Modal-->
    <ModalEdit>
      <template @close="close">
        <v-card>
          <v-card-title>
            <p>ແກ້ໄຂ User ພະແນກ</p>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        v-if="edit_data.user"
                        label="ຊື່"
                        required
                        v-model="edit_data.user.name"
                        :rules="nameRule"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.name }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        v-if="edit_data.user"
                        label="Email"
                        required
                        v-model="edit_data.user.email"
                        :rules="emailRules"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.email }}
                    </p>
                  </v-col>
                </v-row>
<!--                <v-row>-->
<!--                  <v-col cols="12">-->
<!--                    <v-text-field-->
<!--                        label="Password *"-->
<!--                        type="password"-->
<!--                        v-model="edit_data.password"-->
<!--                        :rules="passwordRules"-->
<!--                        required-->
<!--                    ></v-text-field>-->
<!--                    <p class="errors">-->
<!--                      {{ server_errors.password }}-->
<!--                    </p>-->
<!--                  </v-col>-->
<!--                </v-row>-->
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                        v-if="edit_data.user"
                        v-model="edit_data.role.id"
                        :items="roleTypes"
                        item-text="name"
                        item-value="id"
                        label="Role"
                        dense
                        :rules="roleRules"
                    ></v-autocomplete>
                    <p class="errors">
                      {{ server_errors.role_id }}
                    </p>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeUpdateModal()">
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="updateItem()"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalEdit>

    <!--    Delete Modal-->
    <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="deleteItemConfirm"
          >OK
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete>
  </v-container>
</template>

<script>
import {GetOldValueOnInput} from "@/Helpers/GetValue";
import queryOption from "@/Helpers/queryOption";

export default {
  name: "DepartmentView",
  title() {
    return `Vientiane Waste Co-Dev|${this.title}`;
  },
  data() {
    return {
      title: "Department",
      headers: [
        {text: "ລະຫັດ", value: "id"},
        {text: "ຊື່", value: "user.name"},
        {text: "ອີເມວ", value: "user.email"},
        {text: "ພະແນກ", value: "department.name"},
        {text: "ບົດບາດ", value: "role.name"},
        {text: "ວັນທີ", value: "created_at"},
        {text: "ຈັດການຂໍ້ມູນ", value: "actions", sortable: false},
      ],
      loading: false,
      data: [],
      formState: {},
      edit_data: {},
      dataId: "",
      server_errors: {
        name: "",
        email: "",
        role_id:"",
        password:""
      },
      //Pagination
      offset: 12,
      pagination: {},
      per_page: 15,
      search: "",
      oldVal: "",
      selectedRole: "",
      roleTypes: [],
      //Validation
      nameRule: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length >= 2) || "Name must be less than 2 characters",
      ],
      roleRules: [(v) => !!v || "Role is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [(v) => !!v || "Password is required"],
    };
  },
  methods: {
    openAddModal() {
      this.$store.commit("modalAdd_State", true);
    },
    async AddItem() {
      if (this.$refs.form.validate() == true) {
        try {
          this.loading = true;
         await this.$axios
              .post("admin/add-department-user/"+ this.$route.params.id, {
                name: this.formState.name,
                email: this.formState.email,
                password: this.formState.password,
                role_id: this.selectedRole
              }).then((res) => {
            if (res.data.code == 200) {
              this.closeAddModal();
              this.formState = {};
              this.fetchData();
              this.reset();
              this.$store.commit("Toast_State", {
                value: true,
                color: "success",
                msg: res.data.message,
              });
            }
          }).catch((error) => {
            this.loading = false;
            this.$store.commit("Toast_State", {
              value: true,
              color: "error",
              msg: error.response.data.message,
            });
            if (error.response.status == 422) {
              let obj = error.response.data.errors;
              for (let [key, customer] of Object.entries(obj)) {
                this.server_errors[key] = customer[0];
              }
            }
          });
        } catch (error) {
          this.loading = false;
        } finally {
          this.loading = false;
        }
      }
    },
    fetchData() {
      this.$store.commit("Loading_State", true);
      this.$axios
          .get("admin/list-department-users/"+ this.$route.params.id, {
            params: queryOption([
              {page: this.pagination.current_page},
              {per_page: this.per_page},
            ]),
          })
          .then((res) => {
            if (res.data.code == 200) {
              this.loading = false;
              this.$store.commit("Loading_State", false);
              this.data = res.data.data.data;
              this.pagination = res.data.data.pagination;
            }
          })
          .catch((error) => {
            this.$store.commit("Loading_State", false);
            if (error.response.status == 422) {
              let obj = error.response.data.errors;
              for (let [key, message] of Object.entries(obj)) {
                this.server_errors[key] = message[0];
              }
            }
          });
    },

    closeAddModal() {
      this.$store.commit("modalAdd_State", false);
    },
    OpenModalEdit(item) {
      this.edit_data = item;
      this.$store.commit("modalEdit_State", true);
    },
    async updateItem() {
      const data = {
        name: this.edit_data.user.name,
        email: this.edit_data.user.email,
        role_id: this.edit_data.role.id,
      }
      if (this.$refs.form.validate() == true) {
        try {
          this.loading = true;
          await this.$axios
              .put(`admin/edit-department-user/${this.$route.params.id}/${this.edit_data.id}`, data).then((res) => {
                if (res.data.code == 200) {
                  this.closeUpdateModal();
                  this.fetchData();
                  this.reset();
                  this.$store.commit("Toast_State", {
                    value: true,
                    color: "success",
                    msg: res.data.message,
                  });
                }
              }).catch((error) => {
                this.loading = false;
                this.$store.commit("Toast_State", {
                  value: true,
                  color: "error",
                  msg: error.response.data.message,
                });
                if (error.response.status == 422) {
                  let obj = error.response.data.errors;
                  for (let [key, message] of Object.entries(obj)) {
                    this.server_errors[key] = message[0];
                  }
                }
              });
        } catch (error) {
          this.$store.commit("Toast_State", {
            value: true,
            color: "error",
            msg: 'ເກີດຂໍ້ຜິດພາດບາງຢ່າງ',
          });
        } finally {
          this.loading = false;
        }
      }
    },
    closeUpdateModal() {
      this.$store.commit("modalEdit_State", false);
    },

    closeDelete() {
      this.$store.commit("modalDelete_State", false);
    },

    deleteItem(id) {
      this.dataId = id;
      this.$store.commit("modalDelete_State", true);
    },

    async deleteItemConfirm() {
      try {
        this.loading = true;
        await this.$axios
            .delete(`admin/delete-department-user/${this.$route.params.id}/${this.dataId}`)
            .then((res) => {
              if (res.data.code == 200) {
                this.fetchData();
                this.$store.commit("Toast_State", {
                  value: true,
                  color: "success",
                  msg: res.data.message,
                });
                this.loading = false;
                this.$store.commit("modalDelete_State", false);
              }
            })
            .catch((error) => {
              this.$store.commit("Toast_State", {
                value: true,
                color: "error",
                msg: error.response.data.message,
              });
              this.$store.commit("modalDelete_State", false);
              this.loading = false;
            });
      } catch (e) {
        this.$store.commit("Toast_State", {
          value: true,
          color: "error",
          msg: 'ເກີດຂໍ້ຜິດພາດບາງຢ່າງ',
        });
      } finally {
        this.loading = false;
      }

    },
    reset() {
      this.$refs.form.reset();
    },
    Search() {
      GetOldValueOnInput(this);
    },

    // Fetch Roles
    fetchRoles() {
      this.$axios
        .get("admin/ipoint-list-roles")
        .then((res) => {
          this.roleTypes = res.data.listRoles;
        })
        .catch((error) => {
          
        });
    },
  },
  watch: {
    "formState.name": function () {
      this.server_errors.name = "";
    },
    "formState.email": function () {
      this.server_errors.email = "";
    },
    "formState.password": function () {
      this.server_errors.password = "";
    },
    "selectedRole": function () {
      this.server_errors.role_id = "";
    },

    "edit_data.user.name": function () {
      this.server_errors.name = "";
    },
    "edit_data.user.email": function () {
      this.server_errors.email = "";
    },
    "edit_data.role_id": function () {
      this.server_errors.role_id = "";
    },
    search: function (value) {
      if (value == "") {
        this.fetchData();
      }
    },
  },
  created() {
    this.fetchData();
    this.fetchRoles();
  },
};
</script>

<style lang="scss">
@import "../../../public/scss/main.scss";
</style>
