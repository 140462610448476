<template>
  <v-container>
    <v-card class="elevation-0">
        <h2 class="text-center display-5 black--text mt-4 mb-2">
          ຢືນຢັນຂໍ້ມູນການອັບໂຫຼດ
        </h2>
        <p class="errors text-center">{{ moment(new Date()).format('DD-MM-YYYY hh:mm:ss') }}</p>
      <v-card-text>
        <v-container>

            <v-row class="text-center">
              <v-col cols="12">
               <v-card class="elevation-0 light-blue">
                 <v-card-text>
                   <h2>{{transferType.name}} - {{transferType.short_name}}</h2>
                 </v-card-text>
               </v-card>
              </v-col>
            </v-row>
            <br>
            <v-simple-table
                class="elevation-1"
            >
              <template v-slot:default>
                <tbody>
                <tr
                >
                  <td style="width: 160px"><span class="info--text">ຊື່ໄຟລ: </span> </td>
                  <td><span>{{from_file_name}} </span> </td>
                </tr>
                <tr
                >
                  <td style="width: 160px"> <span class="info--text">ເລກບັນຊີຕົ້ນທາງ:</span></td>
                  <td><span>{{fromWalletAccount.account_number}} - {{fromWalletAccount.account_name}}</span> </td>
                </tr>
                <tr
                >
                  <td style="width: 160px"><span class="info--text">ປະເພດທຸລະກຳ:</span>
                  </td> <td><span>{{transferType.name}} - {{transferType.short_name}}</span> </td>
                </tr>
                <tr
                >
                  <td style="width: 160px"><span class="info--text">ຍອດລວມທັງໝົດ:</span></td>
                  <td><span v-if="transactionPoints.length">{{Intl.NumberFormat().format(sumOfTransactions)}} Point - {{ transactionPoints.length }} ລາຍການ</span></td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="btn-info mr-4" text @click="back()">
            ຍົກເລີກ
          </v-btn>
<!--          <v-btn-->
<!--              color="darken-0"-->
<!--              class="btn-info mr-4 elevation-0"-->
<!--              :loading="requestLoading"-->
<!--              :disabled="requestLoading"-->
<!--              @click="reSendOtp"-->
<!--          >-->
<!--            ສົ່ງຄຳຂໍໃໝ່-->
<!--          </v-btn>-->
          <v-btn
              class="btn-primary mr-4"
              text
              :loading="transactionLoading"
              :disabled="transactionLoading"
              @click="requestOtp()"
          >
            ຢືນຢັນການໂອນ
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>

    <ModalAdd>
      <template @close="close">
        <v-card>
            <v-btn
                icon
                dark
                @click="closeAddModal"
                class="pa-4"
            >
              <v-icon class="primary-color">mdi-close</v-icon>
            </v-btn>

          <v-card-text>
            <v-container>
              <h2 class="text-center display-5 black--text mb-0 mt-4">
                Verify Code OTP
              </h2>
              <p class="text-center display-5 black--text mb-8 mt-0">Input Code from SMS {{fromWalletAccount.phone}}</p>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="xl-6 lg-6 md-12 sm-12 xm-12">
                    <v-otp-input
                        ref="otpInput"
                        input-classes="otp-input"
                        separator=""
                        :num-inputs="6"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        class="otp"
                        v-model="otp"
                    />
<!--                    <v-btn text @click="handleClearInput()" small style="margin:auto;">Clear</v-btn>-->
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p class="errors">
                     {{server_errors.otp}}
                    </p>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
<!--              <v-btn-->
<!--                  color="darken-0"-->
<!--                  class="btn-info mr-4 elevation-0"-->
<!--                  :loading="requestLoading"-->
<!--                  :disabled="requestLoading"-->
<!--                  @click="reSendOtp"-->
<!--              >-->
<!--                ສົ່ງຄຳຂໍໃໝ່-->
<!--              </v-btn>-->
              <v-btn
                  color="darken-0"
                  class="btn-primary elevation-0"
                  :loading="btnVerify"
                  :disabled="btnVerify"
                  @click="verifyOtp"
              >
                ຢືນຢັນ OTP
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalAdd>

<!--    ViewModel-->
    <ModalView>
      <template @close="close">
        <v-card>
<!--          <v-btn-->
<!--              icon-->
<!--              dark-->
<!--              @click="closeViewModal"-->
<!--              class="pa-4"-->
<!--          >-->
<!--            <v-icon class="primary-color">mdi-close</v-icon>-->
<!--          </v-btn>-->

          <v-card-text>
            <v-container>
              <div class="text-center">
                <v-icon class="success--text" style="font-size: 54px;">mdi-checkbox-marked-circle</v-icon>
              </div>
              <h2 class="text-center display-5 black--text mt-4 mb-2">
                ໂອນພ້ອຍສຳເລັດແລ້ວ
              </h2>
              <p class="errors text-center">{{ moment(successData.created_at).format('DD-MM-YYYY hh:mm:ss') }}</p>
              <v-row class="text-center">
                <v-col cols="12">
                  <v-card class="elevation-0 light-blue">
                    <v-card-text>
                      <h3>{{successData.refer_number}}</h3>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <br>
              <v-simple-table
                  class="elevation-0"
              >
                <template v-slot:default>
                  <tbody>
                  <tr
                  >
                    <td style="width: 160px"> <span class="info--text">ເລກບັນຕົ້ນທາງ:</span></td>
                    <td v-if="successData.from_wallet"><span>{{successData.from_wallet.account_number}} - {{successData.from_wallet.account_name}}</span> </td>
                  </tr>
                  <tr
                  >
                    <td style="width: 160px"><span class="info--text">ປະເພດທຸລະກຳ:</span>
                    </td> <td v-if="successData.transfer_type"><span>{{successData.transfer_type.name}} - {{successData.transfer_type.short_name}}</span> </td>
                  </tr>

                  <tr
                  >
                    <td style="width: 160px"><span class="info--text">ຍອດລວມທັງໝົດ:</span></td>
                    <td><span>{{Intl.NumberFormat().format(successData.totalPoint)}} Point</span></td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  class="btn-success elevation-0"
                  @click="closeViewModal"
              >
                ສຳເລັດ
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalView>
    <div id="recaptcha-container"></div>
  </v-container>
</template>

<script>
import transaction_point from "@/mixin/transaction_point";
import firebase from "firebase";

export default {
  name: "DepartmentView",
  mixins: [transaction_point],
  title() {
    return `TransactionPoint| Hal Point`;
  },
  data() {
    return {
      transactionPoints: [],
      fromWalletAccount:{},
      transferType:{},
      from_file_name: '',
    }
  },
  methods: {
    fetchFromWallet() {
      const from_wallet_id = localStorage.getItem('from_wallet');
      const from_id = JSON.parse(from_wallet_id);
      this.$store.commit("Loading_State", true);
      this.$axios
          .get("admin/list-wallets")
          .then((res) => {
            if (res.data.code == 200) {
              this.loading = false;
              this.$store.commit("Loading_State", false);
              this.wallets = res.data.data;
            this.fromWalletAccount =  this.wallets.find(x => x.id === from_id);
            }
          })
          .catch((error) => {
            this.$store.commit("Loading_State", false);
            if (error.response.status == 422) {
              let obj = error.response.data.errors;
              for (let [key, message] of Object.entries(obj)) {
                this.server_errors[key] = message[0];
              }
            }
          });
    },
    fetchDataTransferType() {
      const transferType = localStorage.getItem('transfer_type');
      const transfer_type_id = JSON.parse(transferType);
      this.$store.commit("Loading_State", true);
      this.$axios
          .get("admin/list-wallet-transfer-types")
          .then((res) => {
            if (res.data.code == 200) {
              this.loading = false;
              this.$store.commit("Loading_State", false);
              this.transferTypes = res.data.data;
              this.transferType =  this.transferTypes.find(x => x.id === transfer_type_id)
            }
          })
          .catch((error) => {
            this.$store.commit("Loading_State", false);
            if (error.response.status == 422) {
              let obj = error.response.data.errors;
              for (let [key, message] of Object.entries(obj)) {
                this.server_errors[key] = message[0];
              }
            }
          });
    },
    requestOtp() {
      this.initReCaptcha();
      this.otp = '';
      this.requestLoading = true;
      let countryCode = "+85620"; //laos
      let phoneNumber = countryCode + this.fromWalletAccount.phone;
      const appVerifier = this.appVerifier;
      setTimeout(() => {
        firebase.auth().languageCode = "en";
        firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
            .then(confirmationResult => {
              window.confirmationResult = confirmationResult;
              this.requestLoading = false;
              // this.$router.push({name: "verify-otp-points"});
              this.$store.commit("modalAdd_State", true);
            })
            .catch((error) => {
              this.error = error;
              if (error == 400) {
                this.store.commit("Toast_State", {
                  value: true,
                  color: "error",
                  msg: error.errors.message,
                });
              }
              this.requestLoading = false;
            }, 15000);
      })
    },
    reSendOtp() {
      window.location.reload();
      this.initReCaptcha();
      this.otp = '';
      this.requestLoading = true;
      let countryCode = "+85620"; //laos
      let phoneNumber = countryCode + this.fromWalletAccount.phone;
      const appVerifier = this.appVerifier;
      setTimeout(() => {
        firebase.auth().languageCode = "en";
        firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
            .then(confirmationResult => {
              window.confirmationResult = confirmationResult;
              this.requestLoading = false;
              // this.$router.push({name: "verify-otp-points"});
              this.$store.commit("modalAdd_State", true);
            })
            .catch((error) => {
              this.error = error;
              if (error == 400) {
                this.store.commit("Toast_State", {
                  value: true,
                  color: "error",
                  msg: error.errors.message,
                });
              }
              this.requestLoading = false;
            }, 15000);
      })
    },

    initReCaptcha() {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            "expired-callback": function () {
            },
          }
      );
      //
      this.appVerifier = window.recaptchaVerifier;
    },
  },
  computed: {
    sumOfTransactions() {
      return this.transactionPoints.reduce((sum, transaction) => {
        return sum += transaction.point;
      }, 0);
    },
  },
  created() {
    const point = localStorage.getItem('listTransactions');
    this.transactionPoints = JSON.parse(point);
    this.fetchFromWallet();
    this.fetchDataTransferType();
    const from_file = localStorage.getItem('file');
    this.from_file_name = JSON.parse(from_file);
  },

};
</script>

<style lang="scss">
@import "../../../public/scss/main.scss";
</style>
