<template>
  <v-container>
    <v-row class="mb-n6">
      <v-col v-if="$can(['supermanager','departmentmanager'])">
        <v-btn @click="AddPoint" class="btn-primary mr-4 elevation-0"
        >
          <v-icon class="mr-1">mdi-clipboard-flow</v-icon>ໂອນພ້ອຍ
        </v-btn>
      </v-col>
      <v-col>
        <v-select
            v-model="selectedType"
            :items="transferTypes"
            item-text="name"
            item-value="value"
            label="ປະເພດ"
            dense
            outlined
            clearable
        ></v-select>
      </v-col>
      <v-col>
        <v-menu
            v-model="start_menu"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="start_date"
                label="ເລີ່ມວັນທີ"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                dense
                clearable
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="start_date"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
            v-model="end_menu"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="end_date"
                label="ຫາວັນທີ"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                dense
                clearable
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="end_date"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-select
            v-model="selectedFromWallet"
            :items="fromWallets"
            item-text="owner.name"
            item-value="id"
            label="ບັນຊີຕົ້ນທາງ"
            dense
            outlined
            clearable
        ></v-select>
      </v-col>
      <v-col>
        <v-select
            v-model="selectedToWallet"
            :items="toWallets"
            item-text="owner.name"
            item-value="id"
            label="ບັນຊີປາຍທາງ"
            dense
            outlined
            clearable
        ></v-select>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <v-card class="pa-2">
          <v-card-title>
            ຂໍ້ມູນ ການໂອນພ້ອຍ ({{pagination.total}})
            <v-divider class="mx-4" vertical></v-divider>

<!--            <v-btn v-if="selectedRow.length > 0" @click="exportTransaction()" class="btn-success elevation-0 mr-4"-->
<!--            >-->
<!--              <v-icon>mdi-table</v-icon>-->
<!--              Export Excel-->
<!--            </v-btn>-->
<!--            <v-btn v-if="selectedRow.length > 0" @click="exportTransaction()" class="btn-info elevation-0"-->
<!--            >-->
<!--              <v-icon>mdi-file</v-icon>-->
<!--              Export Pdf-->
<!--            </v-btn>-->
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                clearable
                prepend-inner-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                @keyup.enter="Search()"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="data"
              :search="search"
              :disable-pagination="true"
              hide-default-footer
          >
            <template v-slot:[`item.created_at`]="{ item }">
              {{ moment(item.created_at).format("DD-MM-YYYY hh:mm") }}
            </template>
            <!--            <template v-slot:[`item.earn`]="{ item }">-->
            <!--             <v-chip color="success" label>-->
            <!--               {{ Intl.NumberFormat().format(item.point.earn) }}-->
            <!--             </v-chip>-->
            <!--            </template>-->
                        <template v-slot:[`item.point`]="{ item }">
                          <div v-if="item.transaction_type.name ==='spend'">
                            <div class="text--error primary-color" label>
                              {{ Intl.NumberFormat().format(item.totalPoint) }}
                            </div>
                          </div>
                          <div v-else>
                           <div class="succes-color">
                             {{ Intl.NumberFormat().format(item.totalPoint) }}
                           </div>
                          </div>
                        </template>
            <!--            <template v-slot:[`item.total`]="{ item }">-->
            <!--              <v-chip color="info" label>-->
            <!--                {{ Intl.NumberFormat().format(item.point.total) }}-->
            <!--              </v-chip>-->
            <!--            </template>-->
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small @click="viewTransactionPoint(item.id)" class="mr-2"> mdi-eye</v-icon>
              <v-icon small  @click="exportTransaction(item.id)" class="success--text"
                      >mdi-download</v-icon> <span class="mr-2">Excel</span>
              <v-icon small  @click="exportTransactionPdf(item.id)" class="success--text"
                      >mdi-file</v-icon> PDF
            </template>
          </v-data-table>
          <br/>
          <template>
            <Pagination
                v-if="pagination.total_pages > 1"
                :pagination="pagination"
                :offset="offset"
                @paginate="fetchData()"
            ></Pagination>
          </template>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import {GetOldValueOnInput} from "@/Helpers/GetValue";
// import queryOption from "@/Helpers/queryOption";

export default {
  name: "TransactionsView",
  title() {
    return `Transaction Point|${this.title}`;
  },
  data() {
    return {
      title: "Transaction",
      headers: [
        {text: "ເລກອ້າງອີງ", value: "refer_number", width: "220px"},
        {text: "ຈາກກະເປົາ", value: "from_wallet.name", width: "120px", sortable: false},
        {text: "ຈຳນວນພ້ອຍ", value: "point", width: "130px"},
        {text: "ສະຖານະ", value: "transaction_status.name", width: "120px"},
        {text: "ປະເພດໂອນ", value: "transfer_type.name", width: "130px"},
        {text: "ຜູ້ໂອນ", value: "create_by.name", width: "130px"},
        {text: "ວັນທີ", value: "created_at", width: "120px", sortable: false},
        {text: "ຈັດການຂໍ້ມູນ", value: "actions", sortable: false, width: "170px", align: "center"},
      ],
      loading: false,
      data: [],

      //Pagination
      offset: 12,
      pagination: {},
      per_page: 15,
      search: "",
      oldVal: "",
      selectedType: "",

      start_date: "",
      end_date: "",
      start_menu: false,
      end_menu: false,
      selectedRow:[],
      transferTypes: [
        {
          value: 'department',
          name: "ພະແນກ",
        },
        // {
        //   value: 'customer',
        //   name: "ລູກຄ້າ",
        // },
        {
          value: 'employee',
          name: "ພະນັກງານ",
        },
      ],

      selectedFromWallet: "",
      fromWallets: [],
      selectedToWallet: "",
      toWallets: [],
    };
  },
  methods: {

    fetchData() {
      this.$store.commit("Loading_State", true);
      const data = {
        from_wallet_id: this.selectedFromWallet ? this.selectedFromWallet : 'all',
        to_wallet_id: this.selectedToWallet ? this.selectedToWallet : 'all',
        transfer_type: this.selectedType ? this.selectedType : 'all',
        start_date: this.start_date,
        end_date: this.end_date,
        page: this.pagination.current_page,
        per_page: this.per_page
      }
      this.$axios
          .post("admin/list-wallet-transactions",
              //     {
              //   params: queryOption([
              //     {page: this.pagination.current_page},
              //     {
              //       per_page: this.per_page
              //     },
              //   ]),
              // }
              data
          )
          .then((res) => {
            if (res.data.code == 200) {
              this.loading = false;
              this.$store.commit("Loading_State", false);
              this.data = res.data.data.data;
              this.pagination = res.data.data.pagination;
            }
          })
          .catch((error) => {
            this.$store.commit("Loading_State", false);
            if (error.response.status == 422) {
              let obj = error.response.data.errors;
              for (let [key, message] of Object.entries(obj)) {
                this.server_errors[key] = message[0];
              }
            }
          });
    },
    reset() {
      this.$refs.form.reset();
    },
    AddPoint() {
      this.$router.push({name: 'transaction-points-add'});
    },
    viewTransactionPoint(id) {
      this.$router.push({name: 'transaction-points-detail', params: {id: id}});
    },
   async exportTransaction(id) {
     this.$store.commit("Loading_State", true);
    await this.$axios
          .get(
              "admin/export-transaction-point/"+id, {responseType: "blob"}
          )
          .then((res) => {
              const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
              const fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute(
                  "download",
                  "Transaction_Point"+
                  ".xlsx"
              );
              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
            this.$store.commit("Loading_State", false);
          })
          .catch(() => {
            this.$store.commit("Loading_State", false);
            this.$store.commit("Toast_State", this.toast_error);
          });
    },

    async exportTransactionPdf(id) {
      this.$store.commit("Loading_State", true);
      await this.$axios
          .get(
              "admin/export-transaction-point-pdf/"+id, {responseType: "blob"}
          )
          .then((res) => {
            const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
            const fileLink = document.createElement("a");
            fileLink.href = fileUrl;
            fileLink.setAttribute(
                "download",
                "Transaction_Point"+
                ".pdf"
            );
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
            this.$store.commit("Loading_State", false);
          })
          .catch(() => {
            this.$store.commit("Loading_State", false);
            this.$store.commit("Toast_State", this.toast_error);
          });
    },

    Search() {
      GetOldValueOnInput(this);
    },

    fetchWallet() {
      this.$axios
          .get("admin/list-wallets")
          .then((res) => {
            this.fromWallets = res.data.data;
            this.toWallets = res.data.data;
          })
          .catch((error) => {
            this.$store.commit("Loading_State", false);
            if (error.response.status == 422) {
              let obj = error.response.data.errors;
              for (let [key, message] of Object.entries(obj)) {
                this.server_errors[key] = message[0];
              }
            }
          });
    }
  },
  watch: {
    "selectedType": function () {
      this.pagination.current_page = '';
      this.fetchData();
    },
    "start_date": function () {
      this.pagination.current_page = '';
      this.fetchData();
    },
    "end_date": function () {
      this.pagination.current_page = '';
      this.fetchData();
    },
    "selectedFromWallet": function () {
      this.pagination.current_page = '';
      this.fetchData();
    },
    "selectedToWallet": function () {
      this.pagination.current_page = '';
      this.fetchData();
    },
    search: function (value) {
      this.pagination.current_page = '';
      this.pagination = [];
      if (value == "") {
        this.fetchData();
      }
    },
  },
  created() {
    this.fetchData();
    this.fetchWallet();
  },
};
</script>

<style lang="scss">
@import "../../../public/scss/main.scss";
</style>
