<template>
  <v-container>
    <v-row class="mb-n6">
      <v-col>
        <v-btn @click="openAddModal()" class="btn-primary mr-4 elevation-0"
        >
          <v-icon>mdi-plus</v-icon> ເພີ່ມ User
        </v-btn>
        <v-btn @click="AddEmployee()" class="btn-info elevation-0 mr-4"
        >
          <v-icon>mdi-upload</v-icon>
          Import User
        </v-btn>
        <v-btn @click="exportData()" class="info" :loading="exportLoading"
               color="elevation-0"
               :disabled="exportLoading">
          <v-icon>mdi-download</v-icon>
          Export User

        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <v-card class="pa-2">
          <v-card-title>
            <v-row>
              <v-col>
                ຂໍ້ມູນ User ({{ pagination.total }})
              </v-col>
              <v-col>
                <v-autocomplete
                    v-model="selectedFilterType"
                    :items="filterDepartments"
                    item-text="name"
                    item-value="id"
                    label="ພະແນກ"
                    dense
                    clearable
                    :rules="typeRules"
                    outlined
                ></v-autocomplete>
              </v-col>

              <v-col>
                <v-text-field
                    v-model="search"
                    clearable
                    prepend-inner-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                    dense
                    @keyup.enter="Search()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="data"
              :search="search"
              :disable-pagination="true"
              hide-default-footer
          >
            <template v-slot:[`item.created_at`]="{ item }">
              {{ moment(item.created_at).format("DD-MM-YYYY hh:mm ") }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-4" @click="OpenModalEdit(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item.id)"> mdi-delete</v-icon>
            </template>
          </v-data-table>
          <br/>
          <template>
            <Pagination
                v-if="pagination.total_pages > 1"
                :pagination="pagination"
                :offset="offset"
                @paginate="fetchData()"
            ></Pagination>
          </template>
        </v-card>
      </v-col>
    </v-row>

    <!-- Modal Add-->
    <ModalAdd>
      <template @close="close">
        <v-card>
          <v-card-title>
            <p>ເພີ່ມພະນັກງານ</p>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Phone"
                        class="input-number"
                        type="number"
                        required
                        v-model="formState.phone"
                        :rules="phoneRule"
                    ></v-text-field>
                    <p class="errors">
                      <v-icon small class="primary-color" v-if="server_errors.phone">mdi-arrow-right</v-icon>
                      {{ joinData(server_errors.phone) }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                        v-model="selectedDepartment"
                        :items="departments"
                        item-text="name"
                        item-value="id"
                        label="ພະແນກ"
                        dense
                        :rules="typeRules"
                    ></v-autocomplete>
                    <p class="errors">
                      {{ server_errors.department_id }}
                    </p>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeAddModal()">
                ຍົກເລີກ
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="AddItem()"
              >
                ບັນທຶກ
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalAdd>

    <!--    Edit Modal-->
    <ModalEdit>
      <template @close="close">
        <v-card>
          <v-card-title>
            <p>ແກ້ໄຂພະນັກງານ</p>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        v-if="edit_data.user"
                        label="Phone"
                        class="input-number"
                        type="number"
                        required
                        v-model="edit_data.user.user_tel"
                        :rules="phoneRule"
                    ></v-text-field>
                    <p class="errors">
                      <v-icon small class="primary-color" v-if="server_errors.phone">mdi-arrow-right</v-icon>
                      {{ joinData(server_errors.phone) }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                        v-if="edit_data.user"
                        v-model="edit_data.department.id"
                        :items="departments"
                        item-text="name"
                        item-value="id"
                        label="ພະແນກ"
                        dense
                        :rules="typeRules"
                    ></v-autocomplete>
                    <p class="errors">
                      {{ server_errors.department_id }}
                    </p>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeUpdateModal()">
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="updateItem()"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalEdit>

    <v-dialog
        v-model="uploadEmployee"
        persistent
        width="800px"
        max-with="1200px"
        loading
    >
      <template @close="close">
        <v-card :loading="loading" :disabled="loading">
          <v-card-title>
            <h4>Import ພະນັກງານ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                        v-model="selectedDepartment"
                        :items="departments"
                        item-text="name"
                        item-value="id"
                        label="ພະແນກ"
                        dense
                        :rules="typeRules"
                        outlined
                    ></v-autocomplete>
                    <p class="errors">
                      {{ server_errors.department_id }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                        label="ໄຟລບັນຊີປາຍທາງ (xlsx)"
                        show-size
                        accept=".xlsx,.xls"
                        placeholder="Select your files"
                        truncate-length="30"
                        :rules="rulesFile"
                        ref="filename"
                        v-model="filename"
                        outlined
                        dense
                    ></v-file-input>
                  </v-col>
                  <p class="errors">
                    <v-icon small class="primary-color" v-if="server_errors.file">mdi-arrow-right</v-icon>
                    {{ joinData(server_errors.file) }}
                  </p>
                  <p class="errors">
                    <v-icon small class="primary-color" v-if="server_errors.phone_number">mdi-arrow-right</v-icon>
                    {{ joinData(server_errors.phone_number) }}
                  </p>
                  <p class="errors">
                    <v-icon small class="primary-color" v-if="server_errors.user_card_id">mdi-arrow-right</v-icon>
                    {{ joinData(server_errors.user_card_id) }}
                  </p>
                </v-row>
              </v-form>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeUpload()">
                  ຍົກເລີກ
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    :loading="loading"
                    :disabled="loading"
                    @click="UploadEmployeeFile()"
                >
                  ບັນທຶກ
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <!--    Delete Modal-->
    <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="deleteItemConfirm"
          >OK
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete>
  </v-container>
</template>

<script>
import {GetOldValueOnInput} from "@/Helpers/GetValue";
import queryOption from "@/Helpers/queryOption";

export default {
  name: "EmployeeView",
  title() {
    return `Employees|${this.title}`;
  },
  data() {
    return {
      title: "Hal Point",
      headers: [
        // {text: "ລະຫັດພະນັກງານ", value: "user.user_card_id"},
        {text: "ຊື່", value: "user.name"},
        {text: "ເບີໂທ", value: "user.user_tel"},
        {text: "ພະແນກ", value: "department.name"},
        // {text: "ປະເພດ", value: "department.type"},
        {text: "Created", value: "created_at"},
        {text: "ຈັດການຂໍ້ມູນ", value: "actions", sortable: false},
      ],
      loading: false,
      status: false,
      uploadEmployee: false,
      data: [],
      departments: [],
      formState: {},
      edit_data: {},
      dataId: "",
      server_errors: {
        name: "",
        type: "",
        phone: "",
        phone_number: "",
        file: "",
        department_id: "",
      },
      filename: {},
      //Pagination
      offset: 12,
      pagination: {},
      per_page: 100,
      search: "",
      oldVal: "",
      selectedDepartment: "",

      transferType: [],
      selectedTransferType: "",
      exportLoading: false,
      selectedRow: [],
      filterDepartments: [],
      selectedFilterType: "all",
      json_fields: {
        'user_tel': 'user_tel',
        'point': 'point',
        'name': 'name',
        'description': 'description',
      },
      json_data: [],
      json_meta: [
        [
          {
            'key': 'charset',
            'value': 'utf-8'
          }
        ]
      ],
      //Validation
      nameRule: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length >= 2) || "Name must be less than 2 characters",
      ],
      typeRules: [(v) => !!v || "Type is required"],
      transferRule: [(v) => !!v || "Transfer Type is required"],
      phoneRule: [(v) => !!v || "Phone is required"],
      rulesFile: [(v) => !!v || "File is required"],
    };
  },
  methods: {
    openAddModal() {
      this.$store.commit("modalAdd_State", true);
      this.fetchDataDepartment();
    },
    async AddItem() {
      if (this.$refs.form.validate() == true) {
        try {
          this.loading = true;
          await this.$axios
              .post("admin/add-department-employee", {
                phone: this.formState.phone,
                department_id: this.selectedDepartment
              }).then((res) => {
                if (res.data.code == 200) {
                  this.closeAddModal();
                  this.formState = {};
                  this.fetchData();
                  this.reset();
                  this.$store.commit("Toast_State", {
                    value: true,
                    color: "success",
                    msg: res.data.message,
                  });
                }
              }).catch((error) => {
                this.loading = false;
                this.$store.commit("Toast_State", {
                  value: true,
                  color: "error",
                  msg: error.response.data.message,
                });
                if (error.response.status == 422) {
                  let obj = error.response.data.errors;
                  for (let [key, result] of Object.entries(obj)) {
                    this.server_errors[key] = result;
                  }
                }
              });
        } catch (error) {
          this.$store.commit("Toast_State", {
            value: true,
            color: "error",
            msg: 'ເກີດຂໍ້ຜິດພາດບາງຢ່າງ',
          });
        } finally {
          this.loading = false;
        }
      }
    },

    async fetchData() {
      this.$store.commit("Loading_State", true);
      await this.$axios
          .get("admin/list-department-employees/"+ this.selectedFilterType, {
            params: queryOption([
              {page: this.pagination.current_page},
              {per_page: this.per_page},
            ]),
          })
          .then((res) => {
            if (res.data.code == 200) {
              this.loading = false;
              this.$store.commit("Loading_State", false);
              this.data = res.data.data.data;
              this.pagination = res.data.data.pagination;
            }
          })
          .catch((error) => {
            this.$store.commit("Loading_State", false);
            if (error.response.status == 422) {
              let obj = error.response.data.errors;
              for (let [key, message] of Object.entries(obj)) {
                this.server_errors[key] = message[0];
              }
            }
          });
    },

    async fetchDataDepartment() {
      this.$store.commit("Loading_State", true);
      await this.$axios
          .get("admin/list-departments/employee")
          .then((res) => {
            if (res.data.code == 200) {
              this.loading = false;
              this.$store.commit("Loading_State", false);
              this.departments = res.data.data;
            }
          })
          .catch((error) => {
            this.$store.commit("Loading_State", false);
            if (error.response.status == 422) {
              let obj = error.response.data.errors;
              for (let [key, message] of Object.entries(obj)) {
                this.server_errors[key] = message[0];
              }
            }
          });
    },

    async fetchDepartment() {
      await this.$axios
          .get(`admin/list-departments/employee`)
          .then((res) => {
            if (res.data.code == 200) {
              this.filterDepartments = res.data.data;
            }
          })
          .catch((error) => {
            if (error.response.status == 422) {
              let obj = error.response.data.errors;
              for (let [key, message] of Object.entries(obj)) {
                this.server_errors[key] = message[0];
              }
            }
          });
    },

    closeAddModal() {
      this.$store.commit("modalAdd_State", false);
      this.formState = {};
    },
    OpenModalEdit(item) {
      this.edit_data = item;
      this.fetchDataDepartment();
      this.$store.commit("modalEdit_State", true);
    },
    async updateItem() {
      const data = {
        phone: this.edit_data.user.user_tel,
        department_id: this.edit_data.department.id,
      }
      if (this.$refs.form.validate() == true) {
        try {
          this.loading = true;
          await this.$axios
              .put("admin/edit-department-employee/" + this.edit_data.id, data).then((res) => {
                if (res.data.code == 200) {
                  this.closeUpdateModal();
                  this.fetchData();
                  this.reset();
                  this.$store.commit("Toast_State", {
                    value: true,
                    color: "success",
                    msg: res.data.message,
                  });
                }
              }).catch((error) => {
                this.loading = false;
                this.$store.commit("Toast_State", {
                  value: true,
                  color: "error",
                  msg: error.response.data.message,
                });
                if (error.response.status == 422) {
                  let obj = error.response.data.errors;
                  for (let [key, message] of Object.entries(obj)) {
                    this.server_errors[key] = message[0];
                  }
                }
              });
        } catch (error) {
          this.$store.commit("Toast_State", {
            value: true,
            color: "error",
            msg: 'ເກີດຂໍ້ຜິດພາດບາງຢ່າງ',
          });
        } finally {
          this.loading = false;
        }
      }
    },
    closeUpdateModal() {
      this.$store.commit("modalEdit_State", false);
    },

    closeDelete() {
      this.$store.commit("modalDelete_State", false);
    },

    deleteItem(id) {
      this.dataId = id;
      this.$store.commit("modalDelete_State", true);
    },

    async deleteItemConfirm() {
      try {
        this.loading = true;
        await this.$axios
            .delete("admin/delete-department-employee/" + this.dataId)
            .then((res) => {
              if (res.data.code == 200) {
                this.fetchData();
                this.$store.commit("Toast_State", {
                  value: true,
                  color: "success",
                  msg: res.data.message,
                });
                this.loading = false;
                this.$store.commit("modalDelete_State", false);
              }
            })
            .catch((error) => {
              this.$store.commit("Toast_State", {
                value: true,
                color: "error",
                msg: error.response.data.message,
              });
              this.$store.commit("modalDelete_State", false);
              this.loading = false;
            });
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }

    },

    reset() {
      this.$refs.form.reset();
    },

    AddEmployee() {
      this.fetchDataDepartment();
      this.uploadEmployee = true;
    },
    closeUpload() {
      this.uploadEmployee = false;
    },
    async UploadEmployeeFile() {
      if (this.$refs.form.validate() == true) {
        let formData = new FormData();
        formData.append("file", this.filename);
        formData.append("department_id", this.selectedDepartment);
        try {
          this.loading = true;
          await this.$axios
              .post("admin/import-file-excel-department-employees",
                  formData
              ).then((res) => {
                if (res.data.code == 200) {
                  this.closeUpload();
                  this.fetchData();
                  this.reset();
                  this.$store.commit("Toast_State", {
                    value: true,
                    color: "success",
                    msg: res.data.message,
                  });
                }
              }).catch((error) => {
                this.loading = false;
                this.clearErrors();
                this.$store.commit("Toast_State", {
                  value: true,
                  color: "error",
                  msg: error.response.data.message,
                });
                if (error.response.status == 422) {
                  let obj = error.response.data.errors;
                  for (let [key, result] of Object.entries(obj)) {
                    this.server_errors[key] = result;
                  }
                }
              });
        } catch (error) {
          this.$store.commit("Toast_State", {
            value: true,
            color: "error",
            msg: 'ເກີດຂໍ້ຜິດພາດບາງຢ່າງ',
          });
        } finally {
          this.loading = false;
        }
      }
    },
    joinData(data) {
      if (data) {
        return data.join(", ");
      }
    },
    clearErrors() {
      this.server_errors.file = '';
      this.server_errors.user_card_id = '';
      this.server_errors.phone_number = '';
      this.server_errors.department_id = '';
    },

    async exportData() {
      this.exportLoading = true;
      await this.$axios
          .get("admin/export-department-employees/"+ this.selectedFilterType, {responseType: "blob"})
          .then((res) => {
            setTimeout(() => {
              const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
              const fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute(
                  "download",
                  "Employee " +
                  ".xlsx"
              );
              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
              this.exportLoading = false;
            }, 100);
          })
          .catch(() => {
            this.exportLoading = false;
            this.$store.commit("Loading_State", false);
          })
    },

    // exportData() {
    //   this.exportLoading = true;
    //   this.$axios
    //       .get(
    //           "admin/export-department-employees/"+ this.selectedDepartment, {responseType: "blob"}
    //       )
    //       .then((res) => {
    //         setTimeout(() => {
    //           const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
    //           const fileLink = document.createElement("a");
    //           fileLink.href = fileUrl;
    //           fileLink.setAttribute(
    //               "download",
    //               "Bottle" +
    //               ".xlsx"
    //           );
    //           document.body.appendChild(fileLink);
    //           fileLink.click();
    //           document.body.removeChild(fileLink);
    //           this.exportLoading = false;
    //         }, 100);
    //       })
    //       .catch(() => {
    //         this.exportLoading = false;
    //         this.$store.commit("Toast_State", this.toast_error);
    //       });
    // },

    Search() {
      this.pagination = [];
      GetOldValueOnInput(this);
    },
  },
  watch: {
    "formState.phone": function () {
      this.server_errors.phone = "";
    },
    "selectedType": function () {
      this.server_errors.type = "";
    },

    "edit_data.phone": function () {
      this.server_errors.phone = "";
    },
    "edit_data.type": function () {
      this.server_errors.type = "";
    },
    "filename": function () {
      this.clearErrors();
    },
    selectedFilterType: function () {
      this.pagination.current_page = '';
      this.fetchData();
      if(this.selectedFilterType == ''){
        this.selectedFilterType = 'all';
      }
    },
    search: function (value) {
      this.pagination = [];
      this.pagination.current_page = '';
      if (value == "") {
        this.fetchData();
      }
    },
  },
  created() {
    this.fetchData();
    this.fetchDepartment();
  },
};
</script>

<style lang="scss">
@import "../../../public/scss/main.scss";
</style>
