<template>
  <v-container>
    <v-row class="mb-n6">
      <v-col>
        <v-btn @click="openAddModal()" class="btn-primary mr-4 elevation-0" v-if="$can(['supermanager'])"
        >
          <v-icon class="mr-1">mdi-plus</v-icon>
          ເພີ່ມກະເປົາ
        </v-btn>
        <v-btn v-if="$can(['supermanager'])" @click="addPoint()" class="btn-primary elevation-0 mr-4"
        >
          <v-icon class="mr-1">mdi-wallet-plus</v-icon>
          ເຕີມພ້ອຍ
        </v-btn>
        <!--        <v-spacer></v-spacer>-->
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <v-card class="pa-2">
          <v-card-title     v-if="$can(['supermanager','departmentmanager'])">
            ຂໍ້ມູນ ກະເປົາ ({{ pagination.total }})
            <v-divider class="mx-4" vertical></v-divider>
            <v-btn v-if="selectedRow.length > 0" @click="exportData()" class="info" :loading="exportLoading"
                   color="elevation-0"
                   :disabled="exportLoading">
              <v-icon>mdi-download</v-icon>
              Export ກະເປົາ


            </v-btn>
            <v-spacer></v-spacer>
            <!--
            <v-select
              outlined
              dense
              :items="statuses"
              v-model="selectedStatus"
              item-text="name"
              item-value="name"
              label="ສະຖານະ"
            ></v-select>
            <v-spacer></v-spacer>
                        -->
            <v-text-field
                v-if="$can(['supermanager'])"
                v-model="search"
                clearable
                prepend-inner-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                @keyup.enter="Search()"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="data"
              :search="search"
              :disable-pagination="true"
              hide-default-footer
              v-model="selectedRow"
              show-select
          >
<!--            <template v-slot:[`item.created_at`]="{ item }">-->
<!--              {{ moment(item.created_at).format("DD-MM-YYYY hh:mm") }}-->
<!--            </template>-->
<!--            <template v-slot:[`item.earn`]="{ item }">-->
<!--              <v-chip color="success" label>-->
<!--                {{ Intl.NumberFormat().format(item.point.earn) }}-->
<!--              </v-chip>-->
<!--            </template>-->
<!--            <template v-slot:[`item.spend`]="{ item }">-->
<!--              <v-chip color="error" label>-->
<!--                {{ Intl.NumberFormat().format(item.point.spend) }}-->
<!--              </v-chip>
            </template>-->
            <template v-slot:[`item.total`]="{ item }">
              <div class="info--text" label>
                {{ Intl.NumberFormat().format(item.point.total) }}
              </div>
            </template>

            <template v-slot:[`item.actions`]="{ item }" v-if="$can(['supermanager'])">
              <!--  <v-icon small class="mr-2"> mdi-key </v-icon> -->
              <v-icon small class="mr-2" @click="viewWalterDetail(item.id)"> mdi-eye</v-icon>
              <v-icon small class="mr-2" @click="OpenModalEdit(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item)"> mdi-delete</v-icon>
            </template>
          </v-data-table>
          <br/>
          <template>
            <Pagination
                v-if="pagination.total_pages > 1"
                :pagination="pagination"
                :offset="offset"
                @paginate="fetchData()"
            ></Pagination>
          </template>
        </v-card>
      </v-col>
    </v-row>

    <!-- Modal Add-->
    <ModalAdd>
      <template @close="close">
        <v-card>
          <v-card-title>
            <p>ເພີ່ມ Wallet</p>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Account Name"
                        required
                        v-model="formState.account_name"
                        :rules="account_nameRule"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.account_name }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Phone"
                        class="input-number"
                        type="number"
                        required
                        v-model="formState.phone"
                        :rules="phoneRule"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.phone }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                        v-model="selectedType"
                        :items="departmentTypes"
                        item-text="name"
                        item-value="value"
                        label="ປະເພດ"
                        dense
                        :rules="typeRule"
                    ></v-autocomplete>
                    <p class="errors">
                      {{ server_errors.type }}
                    </p>
                  </v-col>
                </v-row>
                <v-row v-if="selectedType ==='department'">
                  <v-col cols="12">
                    <v-autocomplete
                        v-model="selectedOwner"
                        :items="departments"
                        item-text="name"
                        item-value="id"
                        label="ເລືອກພະແນກ"
                        dense
                        :rules="ownerRule"
                    ></v-autocomplete>
                    <p class="errors">
                      {{ server_errors.owner_id }}
                    </p>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeAddModal()">
                ຍົກເລີກ
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="AddItem()"
              >
                ບັນທຶກ
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalAdd>

    <!--    Edit Modal-->
    <ModalEdit>
      <template @close="close">
        <v-card>
          <v-card-title>
            <p>ແກ້ໄຂ Wallet</p>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Account Name"
                        required
                        v-model="edit_data.account_name"
                        :rules="account_nameRule"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.account_name }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Phone"
                        class="input-number"
                        type="number"
                        required
                        v-model="edit_data.phone"
                        :rules="phoneRule"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.phone }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                        v-model="edit_data.type"
                        :items="departmentTypes"
                        item-text="name"
                        item-value="value"
                        label="ປະເພດ"
                        dense
                        :rules="typeRule"
                    ></v-autocomplete>
                    <p class="errors">
                      {{ server_errors.type }}
                    </p>
                  </v-col>
                </v-row>
                <v-row v-if="edit_data.type ==='department'">
                  <v-col cols="12">
                    <v-autocomplete
                        v-if="edit_data.owner"
                        v-model="edit_data.owner.id"
                        :items="departments"
                        item-text="name"
                        item-value="id"
                        label="ເລືອກພະແນກ"
                        dense
                        :rules="ownerRule"
                    ></v-autocomplete>
                    <p class="errors">
                      {{ server_errors.owner_id }}
                    </p>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeUpdateModal()">
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="updateItem()"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalEdit>

    <!--    Delete Modal-->
    <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="deleteItemConfirm"
          >OK
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete>
  </v-container>
</template>

<script>
import {GetOldValueOnInput} from "@/Helpers/GetValue";
import queryOption from "@/Helpers/queryOption";

export default {
  name: "DepartmentView",
  title() {
    return `Wallet|${this.title}`;
  },
  data() {
    return {
      title: "Hal Point",
      headers: [
        {text: "ຊືບັນຊີ", value: "account_name", width: "230px", sortable: false},
        {text: "ເລກບັນຊີ", value: "account_number", width: "180px", sortable: false},
        {text: "ເບີໂທລະສັບ", value: "phone", width: "120px", sortable: false},
        // {text: "ປະເພດ", value: "type", width: "130px"},
        // {text: "ຍອດຮັບ", value: "earn", width: "130px"},
        // {text: "ຍອດໂອນ", value: "spend", width: "130px"},
        {text: "ຍອດຍັງເຫຼືອ", value: "total", width: "130px"},
        // {text: "ວັນທີ", value: "created_at", width: "180px", sortable: false},

        {text: "", value: "actions", sortable: false, width: "130px", align: "center"},
      ],
      loading: false,
      exportLoading: false,
      data: [],
      formState: {},
      edit_data: {},
      dataId: "",
      server_errors: {
        account_name: "",
        account_number: "",
        type: "",
        owner_id: "",
        phone: ""
      },
      //Pagination
      offset: 12,
      pagination: {},
      per_page: 15,
      search: "",
      oldVal: "",
      departments: [],
      selectedRow: [],
      selectedType: "department",
      departmentTypes: [
        {
          value: 'department',
          name: "ພະແນກ",
        },
        // {
        //   value: 'customer',
        //   name: "Customer",
        // }, {
        //   value: 'employee',
        //   name: "ພະນັກງານ",
        // },
      ],
      selectedOwner: "",
      //Validation
      account_nameRule: [
        (v) => !!v || "Account Name is required",
        // (v) => (v && v.length >= 10) || "Account must be less than 10 characters",
      ],
      account_numberRule: [(v) => !!v || "Account Number is required",
        (v) => (v && v.length >= 10) || "Account Number must be more than 10 number"],
      typeRule: [(v) => !!v || "Type is required"],
      phoneRule: [(v) => !!v || "Phone is required"],
      ownerRule: [(v) => !!v || "Owner is required"],

      json_fields: {
        'account_number': 'account_number',
        'point': 'point',
        'account_name': 'account_name',
        'description': 'description',
      },
      json_data: [],
      json_meta: [
        [
          {
            'key': 'charset',
            'value': 'utf-8'
          }
        ]
      ],
    };
  },
  methods: {
    openAddModal() {
      this.$store.commit("modalAdd_State", true);
    },
    async AddItem() {
      if (this.$refs.form.validate() == true) {
        try {
          this.loading = true;
          await this.$axios
              .post("admin/add-wallet", {
                account_name: this.formState.account_name,
                phone: this.formState.phone,
                owner_id: this.selectedOwner,
                type: this.selectedType
              }).then((res) => {
                if (res.data.code == 200) {
                  this.closeAddModal();
                  this.formState = {};
                  this.fetchData();
                  this.reset();
                  this.$store.commit("Toast_State", {
                    value: true,
                    color: "success",
                    msg: res.data.message,
                  });
                }
              }).catch((error) => {
                this.loading = false;
                this.$store.commit("Toast_State", {
                  value: true,
                  color: "error",
                  msg: error.response.data.message,
                });
                if (error.response.status == 422) {
                  let obj = error.response.data.errors;
                  for (let [key, customer] of Object.entries(obj)) {
                    this.server_errors[key] = customer[0];
                  }
                }
              });
        } catch (error) {
          this.loading = false;
        } finally {
          this.loading = false;
        }
      }
    },
    fetchData() {
      this.$store.commit("Loading_State", true);
      this.$axios
          .get("admin/list-wallets", {
            params: queryOption([
              {page: this.pagination.current_page},
              {per_page: this.per_page},
            ]),
          })
          .then((res) => {
            if (res.data.code == 200) {
              this.loading = false;
              this.$store.commit("Loading_State", false);
              this.data = res.data.data.data;
              this.pagination = res.data.data.pagination;
            }
          })
          .catch((error) => {
            this.$store.commit("Loading_State", false);
            if (error.response.status == 422) {
              let obj = error.response.data.errors;
              for (let [key, message] of Object.entries(obj)) {
                this.server_errors[key] = message[0];
              }
            }
          });
    },

    async fetchDepartment() {
      this.$store.commit("Loading_State", true);
      await this.$axios
          .get("admin/list-departments/department")
          .then((res) => {
            if (res.data.code == 200) {
              this.loading = false;
              this.departments = res.data.data;
            }
          })
          .catch((error) => {
            this.$store.commit("Loading_State", false);
            if (error.response.status == 422) {
              let obj = error.response.data.errors;
              for (let [key, message] of Object.entries(obj)) {
                this.server_errors[key] = message[0];
              }
            }
          });
    },

    closeAddModal() {
      this.formState = {};
      this.$store.commit("modalAdd_State", false);
    },
    OpenModalEdit(item) {
      this.edit_data = item;
      this.$store.commit("modalEdit_State", true);
    },
    async updateItem() {
      const data = {
        account_name: this.edit_data.account_name,
        phone: this.edit_data.phone,
        owner_id: this.edit_data.owner.id,
        type: this.edit_data.type,

      }
      if (this.$refs.form.validate() == true) {
        try {
          this.loading = true;
          await this.$axios
              .put(`admin/edit-wallet/${this.edit_data.id}`, data).then((res) => {
                if (res.data.code == 200) {
                  this.closeUpdateModal();
                  this.fetchData();
                  this.reset();
                  this.$store.commit("Toast_State", {
                    value: true,
                    color: "success",
                    msg: res.data.message,
                  });
                }
              }).catch((error) => {
                this.loading = false;
                this.$store.commit("Toast_State", {
                  value: true,
                  color: "error",
                  msg: error.response.data.message,
                });
                if (error.response.status == 422) {
                  let obj = error.response.data.errors;
                  for (let [key, message] of Object.entries(obj)) {
                    this.server_errors[key] = message[0];
                  }
                }
              });
        } catch (error) {
          this.$store.commit("Toast_State", {
            value: true,
            color: "error",
            msg: 'ເກີດຂໍ້ຜິດພາດບາງຢ່າງ',
          });
        } finally {
          this.loading = false;
        }
      }
    },
    closeUpdateModal() {
      this.$store.commit("modalEdit_State", false);
    },

    closeDelete() {
      this.$store.commit("modalDelete_State", false);
    },

    deleteItem(data) {
      this.dataId = data;
      this.$store.commit("modalDelete_State", true);
    },

    async deleteItemConfirm() {
      try {
        this.loading = true;
        await this.$axios
            .delete(`admin/delete-wallet/${this.dataId.id}`, {params: {owner_id: this.dataId.owner.id}})
            .then((res) => {
              if (res.data.code == 200) {
                this.fetchData();
                this.$store.commit("Toast_State", {
                  value: true,
                  color: "success",
                  msg: res.data.message,
                });
                this.loading = false;
                this.$store.commit("modalDelete_State", false);
              }
            })
            .catch((error) => {
              this.$store.commit("Toast_State", {
                value: true,
                color: "error",
                msg: error.response.data.message,
              });
              this.$store.commit("modalDelete_State", false);
              this.loading = false;
            });
      } catch (e) {
        this.$store.commit("Toast_State", {
          value: true,
          color: "error",
          msg: 'ເກີດຂໍ້ຜິດພາດບາງຢ່າງ',
        });
      } finally {
        this.loading = false;
      }

    },
    viewWalterDetail(id) {
      this.$router.push({name: 'wallet-detail', params: {id: id}});
    },
    reset() {
      this.$refs.form.reset();
    },
    Search() {
      GetOldValueOnInput(this);
    },

    async exportData() {
      this.exportLoading = true;

      await this.$axios
          .post("admin/export-wallets",
              {wallets:this.selectedRow.map(item => item.id)}, {responseType: "blob"})
          .then((res) => {
            setTimeout(() => {
              const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
              console.log(fileUrl)
              const fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute(
                  "download",
                  "Wallet " +
                  ".xlsx"
              );
              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
              this.exportLoading = false;
            }, 100);
          })
          .catch((error) => {
            this.exportLoading = false;
            this.$store.commit("Loading_State", false);
            if (error.response.status == 422) {
              let obj = error.response.data.errors;
              for (let [key, message] of Object.entries(obj)) {
                this.server_errors[key] = message[0];
              }
            }
          });
    },
    addPoint() {
      this.$router.push({name: 'add-point'});
    }
  },
  watch: {
    "formState.account_name": function () {
      this.server_errors.account_name = "";
    },
    "formState.account_number": function () {
      this.server_errors.account_number = "";
    },
    "formState.phone": function () {
      this.server_errors.phone = "";
    },
    "selectedType": function () {
      this.server_errors.type = "";
    },
    "selectedOwner": function () {
      this.server_errors.owner_id = "";
    },

    "edit_data.account_name": function () {
      this.server_errors.account_name = "";
    },
    "edit_data.account_number": function () {
      this.server_errors.account_number = "";
    },
    "edit_data.phone": function () {
      this.server_errors.phone = "";
    },
    "edit_data.type": function () {
      this.server_errors.type = "";
    },
    "edit_data.owner.id": function () {
      this.server_errors.owner_id = "";
    },

    search: function (value) {
      if (value == "") {
        this.fetchData();
      }
    },
  },
  created() {
    this.fetchData();
    this.fetchDepartment();
  },
};
</script>

<style lang="scss">
@import "../../../public/scss/main.scss";
</style>
